import React, { useEffect } from 'react';

export default function RedirectToLA(props) {

	useEffect(() => {
		const PTE_LA = process.env.REACT_APP_LA_URL;
		window.location.href = `${PTE_LA}/auth/login`;
	}, []);

	return "";
}