import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
	Alert
} from 'reactstrap';

export default function RedirectComponentCCA(props) {
	
	const [orderResult, setOrderResult] = useState({});
	const [data_loaded, setDataLoaded] = useState(false);

	const useQuery = () => {
       return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();

	useEffect(() => {
		setOrderResult({
			order_status: query.get('order_status'),
			tracking_id: query.get('tracking_id'),
			message: query.get('message'),
			status_message: query.get('status_message'),
			amount: query.get('amount'),
			currency: query.get('currency')
		});

		if (query.get('order_status') === "Failure") {
			setDataLoaded(true);
		} else {
			// redirect to success page immediately.
			props.history.push(`/admin/sub/success?p=${query.get('packageAmount')}`);
		}
	}, []);

	useEffect(() => {
		if (data_loaded) {
			console.log(orderResult)
		}
	}, [data_loaded]);

	return (
		<>
			<div className="siteContent">
				<div className="pteTutorial py-3">
					<div className="borderTitle">
						<h3 className="text-uppercase fw-bold bg-grey">Payment Status</h3>
					</div>

					<div className="row text-center">
						<div className="col">
							<Alert color={orderResult.order_status === "Failure" ? "danger" : "success"}>
								<span style={{fontSize: "20px"}}>
									Payment Status: <strong>{orderResult.order_status}</strong>
								</span>
							</Alert>
						</div>
					</div>
					{orderResult.order_status === "Failure" &&
						<table className="table table-bordered">
							<tbody>
								<tr>
									<td>Amount</td>
									<td>{orderResult.currency} {orderResult.amount}</td>
								</tr>
								<tr>
									<td>Tracking ID</td>
									<td>{orderResult.tracking_id}</td>
								</tr>
								<tr>
									<td>Reason</td>
									<td>{orderResult.message}</td>
								</tr>
							</tbody>
						</table>
					}
					{orderResult.order_status === "Success" &&
						<>Payment Success</>
					}
				</div>
			</div>
		</>
	);
}