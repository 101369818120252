import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import cookie from "react-cookies";
import axios from "axios";
import "components/custom.css";
import API from "api.js";
import { store, persistor } from "store/index";
import { setSub } from "action/index.js";
import NotificationAlert from "react-notification-alert";
import { Redirect, NavLink } from "react-router-dom";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
class SubSuccess extends React.Component {
  state = {
    interval: null,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const p = urlParams.get("p");

    ReactGA.initialize("UA-157984080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init("2474090032742391");
    ReactPixel.init("197333187452630");
    ReactPixel.track("Purchase", { value: p, currency: "USD" });

    //var inter = setInterval(this.checkSub, 3000);
    //this.setState({interval:inter});
    var element = document.getElementsByClassName("wrapper-full-page");
    if (element.length > 0) {
      element[0].classList.add("full-height");
      element[0].classList.remove("wrapper-full-page");
    }
    document
      .getElementsByTagName("body")[0]
      .classList.add("leftSidebarClosed", "package-payment-page");
  }

  componentWillUnmount() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("leftSidebarClosed", "package-payment-page");
  }

  checkSub = async () => {
    let res = null;
    try {
      res = await API({
        method: "GET",
        url: "check/payment",
      });
    } catch (error) {}
    if (res.status === 200) {
      clearInterval(this.state.interval);
      store.dispatch(setSub(true));
    }
  };

  render() {
    return (
      <div class="col-xs-12 successContent">
        <img
          src="/images/subscription-success.png"
          alt="subscription-success"
        />
        <h2>Subscription Successful !</h2>
        <p>
          You have subscribed successfully. <br />
          Please return to the Dashboard to access the new features.
        </p>
      </div>
    );
  }
}

export default SubSuccess;
