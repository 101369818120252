import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Alert,
  ButtonGroup,
  Button,
} from "reactstrap";
import useHttp from "hooks/useHttpRequest";
import { getCountryForTimezone } from "countries-and-timezones";
import { setAlert } from "action/index";
import CCavPlanTab from "components/Plans/CCAvenue/CCavPlanTab.jsx";
import API from "api.js";

export default function CCA(props) {
  const formRef = useRef();
  const [actionUrl, setActionUrl] = useState("");
  const [encRequest, setEncRequest] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [planDuration, setPlanDuration] = useState(1);
  const [userCountry, setUserCountry] = useState("AU");
  const [selectedPlan, setSelectedPlan] = useState("GOLD");
  const [planPrices, setPlanPrices] = useState([]);

  const { response, hasError, request } = useHttp();
  const [plans, setPlans] = useState([]);

  const dispatch = useDispatch();

  const dispatchAlert = (type, message) => {
    dispatch(
      setAlert({
        flag: true,
        type,
        message,
      })
    );
  };

  useEffect(() => {
    const country = getCountryForTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    let countryId = "AU";
    if (country && country.id) {
      countryId = country.id;
      setUserCountry(countryId);
    }

    if (process.env.REACT_APP_ENV === "production") {
      request(
        `https://ipbackend22.languageacademy.com.au/api/v2/getCcavPackages?countryId=${countryId}`
      );
    } else {
      request(`getCcavPackages?countryId=${countryId}`);
    }
    document
      .getElementsByTagName("body")[0]
      .classList.add("adminPlansPage", "leftSidebarClosed");
    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("adminPlansPage", "leftSidebarClosed");
    };
  }, []);

  useEffect(() => {
    if (response) {
      const { plans } = response;
      const showPlans = plans.sort((a, b) => a.price - b.price);

      plans.map((plan, i) => {
        plan.price = plan.price_in;
        plan.currency = "&#x20b9;";
      });

      setPlans(showPlans);
    }
  }, [response]);

  useEffect(() => {
    if (hasError) {
      dispatchAlert(2, "Soemthing went wrong");
      return;
    }
  }, [hasError]);

  const startCCavProcess = (plan_id) => {
    API({
      method: "POST",
      url: `plan/ccavenue`,
      data: { plan_id },
    })
      .then((data) => {
        const res = data.data.data;
        setActionUrl(res.post_url);
        setEncRequest(res.encRequest);
        setAccessCode(res.access_code);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const submitData = (plan_id) => {
    startCCavProcess(plan_id);
  };

  useEffect(() => {
    if (actionUrl !== "" && encRequest !== "" && accessCode !== "") {
      const form = formRef.current;
      if (form) {
        if (typeof form.requestSubmit === "function") {
          form.requestSubmit();
        } else {
          form.dispatchEvent(new Event("submit", { cancelable: true }));
        }
      }
    }
  }, [actionUrl, encRequest, accessCode]);

  const planDurationButtons = (duration) => {
    setPlanDuration(duration);
  };

  const durationButtons = () => {
    return [1, 2, 3];
  };

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  useEffect(() => {
    if (plans.length > 0) {
      const isGold = selectedPlan === "GOLD" ? 1 : 0;
      const prices = [];
      let one_month_price = 0;
      plans.map((plan, i) => {
        if (plan.duration === 1) one_month_price = plan.price;
        if (plan.is_gold === isGold && plan.price > 0) {
          plan.special_class =
            plan.duration === 1
              ? "skyBluePlan"
              : plan.duration === 2
              ? "bluePlan"
              : "";
          plan.base_price =
            plan.duration > 1 ? one_month_price * plan.duration : "&nbsp;";

          const listed_price = one_month_price * plan.duration;
          plan.discount_inr = (
            ((listed_price - plan.price) / listed_price) *
            100
          ).toFixed(0);

          prices.push(plan);
        }
      });
      prices.sort((a, b) => (a.duration < b.duration ? -1 : 1));
      setPlanPrices(prices);
    }
  }, [selectedPlan, plans]);

  const enrolNow = (e, plan_id) => {
    e.preventDefault();
    submitData(plan_id);
  };

  const priceSpeciality = (duration) => {
    const specialities = ["", "", "Best Seller", "Most Savings"];
    if (duration > 0 && duration < 4) return specialities[duration];
    return "";
  };

  return (
    <>
      {userCountry === "IN" && (
        <div className="siteContent py-2 adminPlansOuter">
          <div className="pteTutorial py-3">
            <div className="borderTitle">
              <h3 className="text-uppercase fw-bold bg-grey">
                UPI Payment Gateway
              </h3>
            </div>
            <div className="adminPlansInr row">
              <CCavPlanTab
                selectPlan={selectPlan}
                selectedPlan={selectedPlan}
              />
            </div>
            <div className="shortTimePlans">
              <div className="row shortTimePlansInr">
                {planPrices.map((plan, i) => {
                  return (
                    <div key={i} className={`col ${plan.special_class}`}>
                      <div className="shortPlanInr shadow-lg">
                        <div className="shortPlnHead">
                          <span className="planTopTag">
                            {priceSpeciality(plan.duration)}
                          </span>
                          <h2>
                            <span>
                              {plan.currency !== "AUD" ? "INR" : plan.currency}{" "}
                              ({plan.duration} month
                              {plan.duration > 1 ? "s" : ""})
                            </span>
                            <MonthlyPrice plan={plan} />
                            <span className="renew_note">
                              * Price inclusive of all taxes.
                            </span>
                            <span className="renew_note">
                              * One time Payment.
                            </span>
                          </h2>
                          <Link to="#" onClick={(e) => enrolNow(e, plan.id)}>
                            Pay with UPI
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <form
            ref={formRef}
            method="POST"
            name="submitForm"
            action={actionUrl}
            style={{ display: "none" }}
          >
            <input type="hidden" name="encRequest" value={encRequest} />
            <input type="hidden" name="access_code" value={accessCode} />
          </form>
        </div>
      )}
    </>
  );
}

const MonthlyPrice = ({ plan }) => {
  return (
    <>
      <span className="base_price">
        <b className="base_price_a">{ReactHtmlParser(plan.base_price)}</b>{" "}
        <RenderDiscount plan={plan} />
      </span>
      {plan.price}*{" "}
      <i className="plan_duration">
        ({plan.duration} Month{plan.duration > 1 ? "s" : ""})
      </i>
    </>
  );
};

const RenderDiscount = ({ plan }) => {
  return (
    <>
      {plan.discount_inr > 0 ? (
        <b className="discount">{plan.discount_inr}% Off</b>
      ) : (
        ""
      )}
    </>
  );
};
