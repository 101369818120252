import React,{Component} from "react";
import { BrowserRouter as Router, Link, withRouter } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal, ModalBody, ModalFooter
} from "reactstrap";

import ChromeCheck from "views/ChromeCheck.jsx";
import tenMinutes from "components/10-mins.jpg";
// import ReadAloud from "components/Question/Speaking/ReadAloud.jsx";
import ReadAloud from "components/Question/Speaking/New/ReadAloud.jsx";

import RepeatSentece from "components/Question/Speaking/RepeatSentece.jsx";
// import RepeatSentece from "components/Question/Speaking/New/RepeatSentece.jsx";

// import DescribeImage from "components/Question/Speaking/DescribeImage.jsx";
import DescribeImage from "components/Question/Speaking/New/DescribeImage.jsx";

import RetellLec from "components/Question/Speaking/RetellLec.jsx";
import ShortQue from "components/Question/Speaking/ShortQue.jsx";
import Summary from "components/Question/Writting/Summary.jsx";
import Essay from "components/Question/Writting/Essay.jsx";
import SingleAnswer from "components/Question/Reading/SingleAnswer.jsx";
import MultipleAnswer from "components/Question/Reading/MultipleAnswer.jsx";
import Reorder from "components/Question/Reading/Reorder1.jsx";
import ReadingBlank from "components/Question/Reading/ReadingBlank.jsx";
import WrittingBlank from "components/Question/Reading/WrittingBlank.jsx";
import Summer from "components/Question/Listening/Summer.jsx";
import McqSingle from "components/Question/Listening/McqSingle.jsx";
import McqMulti from "components/Question/Listening/McqMulti.jsx";
import ListeningBlank from "components/Question/Listening/ListeningBlank.jsx";
import MissingWord from "components/Question/Listening/MissingWord.jsx";
import CorrectSummary from "components/Question/Listening/CorrectSummary.jsx";
import Dictation from "components/Question/Listening/Dictation.jsx";
import Highlight from "components/Question/Listening/Highlight.jsx";
import ResourceCheck from "./ResourceCheck.jsx";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// core components
import { NavLink } from "react-router-dom";
import API from "api.js";
import { store } from 'store/index';
import { setAlert } from 'action/index.js';

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData:[],
      currentQuestion:0,
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      type:'',
      result:{
        strategy: 1
      },
      finalResult:[],
      lastFlag:true,
      resultId:false,
      secondCount:0,
      submitProcess:false,
      saveProcess:false,
      dashboardFlag:false,
      resourceTested:false,
      mockCat:false,
      mockTime:20,
      title:'',
      modal:false,
      pending:0,
      msg:'',
      qTime:0,
      nextType:0,
      stopRecording:false,
      soundStat:true,
      soundModal: false,
      soundArr:[2,4,5,13,14,15,16,17,18,19,20],
      q_count:0,
      timeOutFlag:false,
      listeningArr:[],
      speakingArr:[],
      readingArr:[],
      writtingArr:[],
      fullArr:[],
      testIndex:0,
      timeArr:[],
      typeArr:[],
      skip:0,
      stopTimer:true,
      changePhase:false,
      curr_q:0,
      waitFlag:true,
      all_ok:true,
      ids:[],
      submittedId:[],
      complete:0,
      mockBreak:0,
      curr_section: 1,
      save_and_exit: false,
      pending_ques_number_modify: false,
      mock_already_complete: false,
      check_ok: false,
      redirect_to_pending: false,
      redirect_to_begin: false,
      missing_data: {},
			failed: [],
      internet: true,
      lastQuestions: []
    };

    this.isSetMockTimePendingRef = React.createRef();
    this.isSetMockTimePendingRef.current = false;
  }


  onUnloadListener(event) {
    event.preventDefault();
    event.returnValue = "";
    return "";
  }


	/*2,4,5,13,14,15,16,17,18,19,20*/
  componentDidMount() {
		if(!this.props.location.state) {
			document.body.classList.toggle("withoutBar");
			document.body.classList.toggle("sidebar-test-close");
			this.props.history.replace('/admin/mock');
		} else {
      window.addEventListener('beforeunload', this.onUnloadListener);

      let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      this.setState({ all_ok: isChrome });
      // document.body.classList.toggle("sidebar-mini");
      document.body.classList.toggle("sidebar-test-close");

      document.body.classList.toggle("withoutBar");
      document.addEventListener('contextmenu', function(e) {
        e.preventDefault();
      });

      const {id, pending} = this.props.location.state;

      API({
        method: 'GET',
        url: `web/question/detail/${id}/${pending}`
      }).then(data => {
        if ('code' in data.data && data.data.code === 'F') {
          this.props.history.push("/admin/unauthorized");
          return;
        }
        console.log('data: ', data.data);

        let to_pending = false;
        let to_begin = false;
        if (parseInt(pending) === 0 && data.data.resume == true) {
          to_pending = true;
        } else if (parseInt(pending) === 1 && data.data.resume == false) {
          to_begin = true;
        }

        this.setState({ mock_already_complete: data.data.complete === 1 ? true : false, check_ok: true, redirect_to_pending: to_pending, redirect_to_begin: to_begin }, () => {

          let speak = [];
          let write = [];
          let read = [];
          let listen = [];
          let fullArr = [];
          let timeArr = [];
          let typeArr =[];

          let { mock, q_count, curr_q, skip } = data.data;
          let mockBreak = mock.break;


          // added on 22-july-2022
          let mock_state = parseInt(pending); // mock pending = 1.
          if ( mock_state === 0 && mockBreak === 1 ) {
            this.setState({ waitFlag: false });
          } else if (mock_state === 1 && skip < 2) {
            if (mockBreak === 1) {
              this.setState({ waitFlag: false });
            }
          } else if (mock_state === 1 && skip >= 2){
            this.setState({ waitFlag: false });
          }

          if (mock_state === 1) {
            this.setState({ curr_section: skip + 1 });
          }
          // ----- 22-july-2022

          // if (mockBreak === 1) {
          //   this.setState({ waitFlag: false });
          // }

          mock.question.map((v) => {
            if ([1,2,3,4,5,6,7].includes(v.subcategory_id)) {
              speak.push(v)
            }
            /*else if([6,7].includes(v.subcategory_id)){
              write.push(v)
            }*/
            else if([8,9,10,11,12].includes(v.subcategory_id)){
              read.push(v)
            }else if([13,14,15,16,17,18,19,20].includes(v.subcategory_id)){
              listen.push(v);
            }
          });


          if (true) {
            fullArr.push(speak);
            timeArr.push(mock.speaking_time + mock.writting_time);
            typeArr.push(1);
          }
          /*if (write.length > 0) {
            fullArr.push(write);
            timeArr.push(data.data.mock.writting_time);
            typeArr.push(2);
          }*/

          if (true) {
            fullArr.push(read);
            timeArr.push(mock.reading_time);
            typeArr.push(2);
          }

          if (true) {
            fullArr.push(listen);
            timeArr.push(mock.listening_time);
            typeArr.push(3);
          }
          const lastQues = [];
          if(speak.length > 0) {
            const {id, skip} = speak[speak.length - 1]
            lastQues.push({id, skip});
          }
          if(read.length > 0) {
            const {id, skip} = read[read.length - 1]
            lastQues.push({id, skip});
          }
          if(listen.length > 0) {
            const {id, skip} = listen[listen.length - 1]
            lastQues.push({id, skip});
          }
          console.log(lastQues);
      /**
      * Providing question Count. Sequencing for result.
      */
      let seq = parseInt(pending) === 0 ? 1 : (data.data.q_count + 1);
      for(let section in fullArr) {
        for (let item in fullArr[section]) {
          fullArr[section][item].question_number = seq;
          seq++;
        }
      }

          this.setState({
            ids: fullArr[data.data.skip],
            typeArr: typeArr,
            timeArr: timeArr,
            fullArr: fullArr,
            listeningArr: listen,
            speakingArr: speak,
            writtingArr: write,
            readingArr: read,
            title: mock.title,
            mockCat: mock.category,
            skip: data.data.skip,
            testIndex: data.data.skip,
            questionData: fullArr[data.data.skip],
            type: fullArr[data.data.skip][0].subcategory_id,
            mockBreak: mockBreak
          }, () => {

            // var intervalId = setInterval(this.timer, 1000);

            let soundStat = true;
            if (this.state.soundArr.includes(this.state.questionData[0].subcategory_id)) {
              soundStat = false;
            }

            let timeVar = timeArr[data.data.skip];
            if (parseInt(pending) === 1) {
              timeVar = data.data.time;
            }

            console.log('11111111111111111111');
            this.setState({
              q_count: q_count,
              curr_q: curr_q,
              soundStat: soundStat,
              // intervalId: intervalId,
              secondCount: parseInt(parseFloat(timeVar)*60),
              mockTime: parseInt(parseFloat(timeVar)*60),
              pending_ques_number_modify: parseInt(pending) === 1 ? true : false
            });
          });
        });

      }).catch(() => { });

      navigator.getUserMedia({ audio: true }, () => {
        this.setState({ isBlocked: false });
      }, () => {
        this.setState({ isBlocked: true })
      });
    }

    return () => window.removeEventListener("beforeunload", this.onUnloadListener);
  }

  componentWillUnmount = () => {
    // document.body.classList.toggle("sidebar-mini");
    document.body.classList.toggle("withoutBar");
    document.body.classList.toggle("sidebar-test-close");
    document.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    });

    clearInterval(this.state.intervalId);
    window.removeEventListener("beforeunload", this.onUnloadListener);
  }

  nextSection = (e) => {

    let { curr_section, fullArr, testIndex, timeArr } = this.state;
  	let inc_next_section = curr_section + 1;

    this.setState({
      ids: fullArr[testIndex],
      curr_q: 0,
      currentQuestion: 0,
      changePhase: false,
      questionData: fullArr[testIndex],
      type: fullArr[testIndex][0].subcategory_id,
      curr_section: inc_next_section,
      pending_ques_number_modify: false
    });
  }

  exitSection = (e) => {
    console.log('-------------------------------exitSection-------------------------------');
    console.log('type');
    console.log(this.state.fullArr[this.state.testIndex][0].subcategory_id);
    console.log('timeArr');
    console.log(this.state.timeArr);
    console.log('typeArr');
    console.log(this.state.typeArr);
    console.log('testIndex');
    console.log(this.state.testIndex);
    console.log('time');
    console.log(this.state.timeArr[this.state.testIndex]);
    console.log('mock_id');
    console.log(this.props.location.state.id);
    let bodyFormData = new FormData();
    bodyFormData.append('mock_id', this.props.location.state.id);
    bodyFormData.append('time', this.state.timeArr[this.state.testIndex]);
    bodyFormData.append('pending', 1);
    bodyFormData.append('complete', 0);

    API({
      method: 'POST',
      url: 'exit/mocksection',
      data: bodyFormData
    }).then(data => {
      clearInterval(this.state.intervalId);
      document.getElementById("start_next_section").disabled = true;
      document.getElementById("exit_section").disabled = true;
      document.location.href  = "/admin/dashboard";

    }).catch(() => { });
  }

  submitResponse = (data,f=false) => {

    let obj = {
      data: data.data,
      text_answer: data.answer,
      length: data.length,
      correct: data.correct
    };
    if (f) { } else {
      this.setState({ result: obj });
    }
  }

  resultStatus = () => {
    this.setState({ is_result: true });
  }


  setMockTime = async () => {
    console.log('hereisstart')
    if (this.isSetMockTimePendingRef.current) return;
    console.log('hereismidd')
    let { timeArr, testIndex, ids, mockBreak } = this.state;

    let bodyFormData = new FormData();
    let time = 0;
    bodyFormData.append('mock_id', this.props.location.state.id);

    if (timeArr.length === (testIndex + 1)) {
      bodyFormData.append('time', 0);
    } else {
      bodyFormData.append('time', timeArr[testIndex + 1]);
      if (parseInt(testIndex) === 1 && mockBreak === 0) {
        time = 10;
      } else {
        time = timeArr[testIndex + 1];
      }
    }

    ids.map((v,k) => {
      if (k !== 0) {
        bodyFormData.append('id[]', `${v.id}-${v.question_number}`);
        // bodyFormData.append('id[]', v.id);
      }
    });

    console.log(ids, testIndex);
    
    bodyFormData.append('skip', testIndex + 1);

    try {
      this.isSetMockTimePendingRef.current = true;
      await API({
        method: 'Post',
        url: 'set/mockTime',
        data: bodyFormData
      });
      this.setState({ secondCount: time*60 });
    } catch (error) {
      //handle error
    } finally {
      this.isSetMockTimePendingRef.current = false;
    }
    // .then(data => {
      
    // }).catch(()=>{});
  };

  timer = async () => {
    let connection;
    if(typeof store.getState().intStatus === undefined || typeof store.getState().intStatus === "undefined") {
      connection = true;
    } else {
      connection = store.getState().intStatus;
    }
    
    if(connection) {
      if(!this.state.internet) {
        this.setState({internet: true});
      }
      let { secondCount, typeArr, testIndex, timeArr, waitFlag, ids, stopTimer, qTime, intervalId, mockBreak } = this.state;

      if (secondCount <= 1) {

        if(typeArr[testIndex] === 3 && waitFlag) { // when break time up.
          this.skipWait();
          return true;
        }

        this.nextButton('d', 3);
        console.log('------- timeout');

      /**
      * Timeout case.
      */
        this.setMockTime();


        /**
         * Trying to stop timer.
         */
        // this.setState({
        //   secondCount: secondCount - 1,
        //   qTime: qTime + 1
        // });

        if (testIndex === 2) {
          clearInterval(intervalId);
        }

        // return;
      }

      if (stopTimer) {

        this.setState({
          secondCount: secondCount - 1,
          qTime: qTime + 1
        });
      }
    } else {
      if(this.state.internet) {
        this.setState({internet: false});
      }
    }
  }

  /* New next Button.
  * For old Button please check commit "c709e6af94dfce6abd17be05d3473447815e553b" in FullTest1.jsx
  * OR
  * Check in FullTest.jsx "nextButton" method
  */
  nextButton = (e, p) => {
    console.log('p:', p)
    let { soundStat, typeArr, testIndex, waitFlag, secondCount, mockTime } = this.state;
    let pending = 0;
    let msg = "";
    let complete = 0;
    let save_and_exit = false;

    if (p === 0 && !soundStat) {
      this.setState({ soundModal: true });
      return;

    } else if ( p === 0 && soundStat ) {
      pending = 0;
      msg = "Do you want to go to next Question?";

    } else if (p === 1) {
      pending = 0;
      msg = "Do you want to submit this section?";
      complete = typeArr[testIndex] === 3 ? 1 : 0;

    } else if (p === 2) { // Save & Exit
      pending = 1;
      msg = "Do you want to save and exit mock test?";
      save_and_exit = true;

    } else if (p === 3) {

      pending = typeArr[testIndex] === 3 ? 0 : 1;
      complete = pending === 1 ? 0 : 1;
      msg = "Timeout";
      // pending = 1;
    }

    this.setState({
      pending,
      msg,
      nextType: p,
      complete,
      save_and_exit
    }, () => {
      this.setState({modal: true})
    });
  }

  toggleYes = (event) => {
  	let { nextType, curr_section } = this.state;

    this.setState({
      stopRecording: true,
      modal: false,
      // dashboardFlag: nextType === 3 && curr_section === 3 ? true : false
    });
  }

  nextQuestion = async (obj) => {

    console.log('obj-->', obj);

    let obj2 = {
      data: obj.data,
      text_answer: obj.answer,
      length: obj.length,
      correct: obj.correct,
      second: obj.time,
      lang: obj.lang,
      html: obj.html,
      audio_text: obj.audio_text,
      duration: obj.duration,
      strategy: this.state.result.strategy,
    };

    let { nextType, fullArr, testIndex, timeArr, typeArr, waitFlag, intervalId, soundArr, questionData, currentQuestion, save_and_exit } = this.state;

    let obj1 = await this.finalObjectCreation(obj2);

    this.finalSubmit(obj1, nextType);

    if(nextType === 1) {
      this.submitFailed();
      if (fullArr.length >= testIndex + 2) {

        this.setState({
        	stopRecording: false,
        	modal: false,
        	stopTimer: true,
        	testIndex: (testIndex + 1),
        	changePhase: true,
        	nextType: 3,
        	secondCount: parseFloat(timeArr[testIndex + 1])*60,
        	mockTime: parseFloat(timeArr[testIndex + 1])*60
        }, () => {

          let { typeArr, testIndex, waitFlag } = this.state;
          if(typeArr[testIndex] === 3 && waitFlag) {
            this.setState({
            	secondCount: 600,
            	mockTime: 600
            });
          }
        });
      } else {
        this.setState({
        	stopRecording: false,
        	modal: false,
        	dashboardFlag: true
        });
        store.dispatch(setAlert({
        	'flag': true,
        	'type': 1,
        	'message': `Mock ${save_and_exit ? 'saved' : 'submitted'} successfully`
        }));
      }

    } else if(nextType === 3) {
      this.submitFailed();
      if (fullArr.length >= testIndex + 2) {
        this.setState({
        	stopRecording: false,
        	modal: false,
        	stopTimer: true,
        	testIndex: (testIndex+1),
        	changePhase: true,
        	nextType: 3
        });
      } else {
        this.setState({
        	stopRecording: false,
        	modal: false,
        	dashboardFlag: true
        });
        store.dispatch(setAlert({
        	'flag': true,
        	'type': 1,
        	'message': `Mock ${save_and_exit ? 'saved' : 'submitted'} successfully`
        }));
      }

    } else if(nextType === 2) {
      this.submitFailed();
        this.setState({
        	stopRecording: false,
        	modal: false,
        	dashboardFlag: true
        });
        clearInterval(intervalId);
        store.dispatch(setAlert({
        	'flag': true,
        	'type': 1,
        	'message': `Mock ${save_and_exit ? 'saved' : 'submitted'} successfully`
        }));
    } else if(nextType === 0) {

      let soundStat = true;
      if (soundArr.includes(questionData[currentQuestion+1].subcategory_id)) {
        soundStat = false;
      }

      let currentStrategy = this.state.result.strategy;
      this.setState({
      	soundStat: soundStat,
      	stopRecording: false,
      	result: {
          strategy: currentStrategy
        },
      	currentQuestion: currentQuestion + 1,
      	type: questionData[currentQuestion+1].subcategory_id,
      	modal: false
      }, () => {
      	this.forceUpdate();
    	});
    }
  }

  skipWait = () => {
  	let { timeArr, testIndex } = this.state;
    this.setState({
    	waitFlag: false,
    	secondCount: parseFloat(timeArr[testIndex])*60,
    	mockTime: parseFloat(timeArr[testIndex])*60
    });
  }

  finalSubmit = (obj1, type) => {
    
  	let { questionData, currentQuestion, secondCount, qTime, complete, timeArr, testIndex, pending, curr_q, ids, typeArr, save_and_exit } = this.state;
    let curr = questionData[currentQuestion];
    let obj = [];
    let time = secondCount;
    let tFlag = false;
    obj.push(obj1);

    let finalArr = [];

    let bodyFormData = new FormData();
    if(this.props.location.state.pending === 1){
      bodyFormData.append('result_id', this.props.location.state.id);
    }
    bodyFormData.append('q_count', questionData.length);
    bodyFormData.append('mock_id', this.props.location.state.id);
    bodyFormData.append('q_time', qTime);
    bodyFormData.append('complete', complete);
    bodyFormData.append('question_number', curr.question_number);

    if (secondCount <= 0) {
  		time = (timeArr.length === testIndex + 1 ? 0 : timeArr[testIndex + 1] * 60);
  		// bodyFormData.append('time', timeArr.length === testIndex + 1 ? 0 : timeArr[testIndex + 1] * 60);
    }

    bodyFormData.append('pending', pending);
    if (type == 1) {
      // bodyFormData.append('skip', 1);
      time = (timeArr[testIndex+1] * 60);
    }

    // in case of timeout
    if(type === 3) {
      bodyFormData.append('curr_q', 0);
    }
    else {
      if (questionData.length === currentQuestion+1) {
  
        bodyFormData.append('curr_q', pending === 1 ? (currentQuestion + curr_q) : 0);
      } else {
        bodyFormData.append('curr_q', pending === 1 ? (currentQuestion + curr_q) : (currentQuestion + curr_q + 1));
        // bodyFormData.append('curr_q', currentQuestion + curr_q);
        // console.log('curr_ques: ', currentQuestion + curr_q);
      }
    }


    obj.map((v,k) => {

      if ([6,7,13].includes(v.type)) {
        tFlag = true;

    		if (parseInt(type) !== 1) {
    			time = time < 1 ? 1 : (!save_and_exit ? (secondCount - v.second) : secondCount);
    		}
      } else {
        time = parseInt(type) === 1 ? time : secondCount;
      }
  	  time = (parseInt(type) === 1 && testIndex === 2) ? 0 : time;
  	  bodyFormData.append('time', time);

      if (!save_and_exit) {
        if(curr.skip) {
          bodyFormData.append('skip', curr.skip);
        }
        bodyFormData.append('response[]', v.response);
        bodyFormData.append('device', 'website');

        if([1,2,3,4,5].includes(v.type)) {
          bodyFormData.append('text[]', v.answer);
          bodyFormData.append('selected[]', v.text_answer);
          bodyFormData.append('text_answer[]', v.text_answer);
          bodyFormData.append('answer[]', v.ans);
          bodyFormData.append('lang[]', v.lang);
          bodyFormData.append('html[]', v.html);
          bodyFormData.append('audio_text[]', v.audio_text);
          bodyFormData.append('q_ans[]', v.q_ans);
          bodyFormData.append('length[]', v.length);
          bodyFormData.append('ans[]', v.q_ans);
          bodyFormData.append('duration[]', v.duration);
          if (v.data == "") {
            bodyFormData.append('file[]',  new Blob(['hello worllllllllllllld']));
          } else if (v.data === undefined){
            bodyFormData.append('file[]',  new Blob(['hello worlppppppppppppd']));
          } else {
            bodyFormData.append('file[]', v.data);
          }

          // Add strategy only for read-aloud.
          if (parseInt(v.type) === 1) {
            bodyFormData.set('strategy', v.strategy);
          }

          bodyFormData.append('type[]', v.type);
          bodyFormData.append('correct[]', v.correct);
          bodyFormData.append('script[]', v.script);
          bodyFormData.append('id[]', v.id);
        } else if ([6,7].includes(v.type)) {
          bodyFormData.append('text[]', v.answer);
          bodyFormData.append('text_answer[]', v.text_answer);
          bodyFormData.append('selected[]', v.text_answer);
          bodyFormData.append('type[]', v.type);
          bodyFormData.append('audio_text[]', v.audio_text);
          bodyFormData.append('answer[]', v.q_ans);
          bodyFormData.append('script[]', v.script);
          bodyFormData.append('ans[]', v.q_ans);
          bodyFormData.append('lang[]', v.lang);
          bodyFormData.append('html[]', v.html);
          bodyFormData.append('file[]',  new Blob(['hello worlppppppppppppd']));
          bodyFormData.append('correct[]', v.correct);
          bodyFormData.append('length[]', v.length);
          bodyFormData.append('id[]', v.id);
          bodyFormData.append('q_ans[]', v.q_ans);
          bodyFormData.append('duration[]', v.duration);
        } else if([8,9,10,11,12].includes(v.type)) {
          bodyFormData.append('text[]', v.answer);
          bodyFormData.append('selected[]', v.text_answer);
          bodyFormData.append('type[]', v.type);
          bodyFormData.append('lang[]', v.lang);
          bodyFormData.append('text_answer[]', v.text_answer);
          bodyFormData.append('answer[]', v.correct);
          bodyFormData.append('script[]', v.script);
          bodyFormData.append('correct[]', v.correct);
          bodyFormData.append('ans[]', v.q_ans);
          bodyFormData.append('length[]', v.length);
          bodyFormData.append('id[]', v.id);
          bodyFormData.append('audio_text[]', v.audio_text);
          bodyFormData.append('html[]', v.html);
          bodyFormData.append('file[]',  new Blob(['hello worlppppppppppppd']));
          bodyFormData.append('q_ans[]', v.q_ans);
          bodyFormData.append('duration[]', v.duration);
        } else if([13,14,15,17,19,20,18,16].includes(v.type)){
          bodyFormData.append('text[]', v.answer);
          bodyFormData.append('selected[]', v.text_answer);
          bodyFormData.append('type[]', v.type);
          bodyFormData.append('answer[]', v.correct);
          bodyFormData.append('lang[]', v.lang);
          bodyFormData.append('script[]', v.script);
          bodyFormData.append('ans[]', v.q_ans);
          bodyFormData.append('correct[]', v.correct);
          bodyFormData.append('length[]', v.length);
          bodyFormData.append('html[]', v.html);
          bodyFormData.append('audio_text[]', v.audio_text);
          bodyFormData.append('text_answer[]', v.text_answer);
          bodyFormData.append('id[]', v.id);
          bodyFormData.append('file[]',  new Blob(['hello worlppppppppppppd']));
          bodyFormData.append('q_ans[]', v.q_ans);
          bodyFormData.append('duration[]', v.duration);
        }
      }
    });

    API({
      method: 'POST',
      url: `submit/mock`,
      data: bodyFormData
    }).then(data => {

      let { typeArr, testIndex } = this.state;
      let filteredAry = ids.filter(function(e) { return e.id !== obj[0].id });
      if (tFlag) {

        if (typeArr[testIndex] === 2) {
          this.setState({
          	qTime: 0,
          	ids: filteredAry
          });
        } else {
          this.setState({
          	secondCount: time,
          	qTime: 0,
          	ids: filteredAry
          });
        }
      } else {
        this.setState({
        	qTime: 0,
        	ids: filteredAry
        });
      }

      if (data.status === 200 && data.data.status === 200) {} else {
        this.setState({ submitProcess: false });
        store.dispatch(setAlert({
        	flag: true,
        	type: 2,
        	message: "Something went wrong."
        }));
      }
    }).catch(() => {
		// store failed responses somewhere and show later.
    let arr = [];
    arr = this.state.failed;
    arr.push(bodyFormData);
    this.setState({failed: arr});
	});
  }

  submitFailed = () => {
	  let data = [];
	  let formData = new FormData();
	  this.state.failed.forEach((value, index) => {
		let object = {};
		// value.forEach((value, key) => console.log(key , value));
		value.forEach((value, key) => {
			if(key === 'file[]') {
				formData.append('files[]', value)
			} else {
				object[key] = value;
				formData.append(`data[${index}][${key}]`, value)
			}
		});
		data.push(object);
	  })
	  setTimeout(() => {
		API({
      method: "POST",
      url: "submitFailed/mock",
      data: formData,
		})
		.then(() => {this.setState({failed: []});})
		.catch(() => {});
	  }, 2000)
	}

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  }

  soundChecked = () => {
    this.setState({ soundStat: true });
  }

  showTest = () => {
    var intervalId = setInterval(this.timer, 1000);
    this.setState({
    	resourceTested: true,
    	secondCount: this.state.mockTime,

      intervalId: intervalId
    });
    this.forceUpdate();
  }

  closeSoundModal = () => {
    this.setState({ soundModal: false });
  }

  getTypeName = () => {
    let type = "d";
    let { typeArr, testIndex } = this.state;
    switch(typeArr[testIndex]){
      case 1:
        type = "Speaking & Writing";
        break;
      case 2:
        type = "Reading Test";
        break;
      case 3:
        type = "Listening Test";
        break;
    }
    return type;
  }

  closeTimeoutModal = () => {
    this.setState({ timeOutFlag: false });
  }

  finalObjectCreation = (obj1 = false) => {
  	let { questionData, currentQuestion, result } = this.state;
    let qdata = questionData[currentQuestion];
    let response = true;
    if (Object.keys(result).length === 0 ) {
      response = true;
    }
    let obj = {};



    if (obj1) {
      obj = {
	      ...obj1,
	      type: qdata.subcategory_id,
	      id: qdata.id,
	      response: response,
	      answer: qdata.question,
	      script: qdata.audio_script,
	      q_ans: qdata.answer
	    }
    } else {
      obj = {
	      ...this.state.result,
	      type: qdata.subcategory_id,
	      id: qdata.id,
	      response: response,
	      answer: qdata.question,
	      script: qdata.audio_script,
	      q_ans: qdata.answer
	    }
    }
    return obj;
  }

  setStrategy = (st) => {
    const r = this.state.result;
    r.strategy = st;
    this.setState({
      result: r
    });
  }

  redirectToMock = (id, pending) => {
		this.props.history.push({
			pathname: `/admin/fulltest`,
			state: {
				id,
				pending
			}
		})
		window.location.reload();
	}


  render() {

  	let {
  		questionData, currentQuestion, stopRecording, type, all_ok, timeOutFlag,
  		soundModal, modal, msg, nextType, typeArr, resourceTested, fullArr, timeArr,
  		mockBreak, dashboardFlag, changePhase, secondCount, curr_q,
  		saveProcess, resultId, submitProcess, waitFlag, testIndex, save_and_exit, mock_already_complete, check_ok,
      redirect_to_begin, redirect_to_pending } = this.state;

    let props = {
      submit: this.submitResponse,
      showResult: this.resultStatus,
      data: questionData[currentQuestion],
      resultMode: false,
      testMode: true,
      soundChecked: this.soundChecked,
      stopRecording: this.state.stopRecording,
      nextQuestion: this.nextQuestion,
      strategy: this.state.result.strategy,
      setStrategy: this.setStrategy
    };
    let que;
    let title ="";
    let ques_title ="";
    if (questionData.length >0) {
      ques_title = questionData[currentQuestion].q_title;
    }


    switch(type) {
      case 1:
        que = <ReadAloud {...props} key={currentQuestion} />;
        title = "Look at the text below. In the 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 35 seconds to read aloud.";
        break;
      case 2:
        que = <RepeatSentece {...props} key={currentQuestion} />;
        title = "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.";
        break;
      case 3:
        que = <DescribeImage {...props} key={currentQuestion} />;
        title = "Look at the Image below. In 25 seconds,please speak into the microphone and describe in detail what the graph showing. You will have 40 seconds to give your response.";
        break;
      case 4:
        que = <RetellLec {...props}  key={currentQuestion}/>;
        title = "Listen to and read a description of a situation. You will have 20 seconds to think about your answer. Then you will hear a beep. You will have 40 seconds to answer the question. Please answer as completely as you can.";
        break;
      case 5:
        que = <ShortQue {...props} />;
        title = "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.";
        break;
      case 6:
        que = <Summary {...props} />;
        title = "Read the passage below. Summarize the passage using between 25 and 50 words. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.";
        break;
      case 7:
        que = <Essay {...props} />;
        title = "Read a description of a situation. Then write an email about the situation. You will have 9 minutes. You should aim to write at least 100 words.";
        break;
      case 8:
        que = <SingleAnswer {...props} />;
        title = "Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
        break;
      case 9:
        que = <MultipleAnswer {...props} />;
        title = "Read the text and answer the question by selecting all the correct responses. More than one response is correct.";
        break;
      case 10:
        que = <Reorder {...props} />;
        title = "The textbox in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.";
        break;
      case 11:
        que = <ReadingBlank {...props} />;
        title = "In the text below some words are missing. Drag words from the text box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.";
        break;
      case 12:
        que = <WrittingBlank {...props} />;
        title = "Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.";
        break;
      case 13:
        que = <Summer {...props} />;
        title = "You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should write 20-30 words. You have 8 minutes to finish the task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.";
        break;
      case 14:
        que = <McqSingle {...props} />;
        title = "Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
        break;
      case 15:
        que = <McqMulti {...props} />;
        title = "Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.";
        break;
      case 16:
        que = <ListeningBlank {...props} />;
        title = "You will hear a recording. Type the missing words in each blank.";
        break;
      case 17:
        que = <CorrectSummary {...props} />;
        title = "You will hear a recording. Click on the paragraph that best relates to the recording.";
        break;
      case 18:
        que = <MissingWord {...props} />;
        title = "You will hear a recording about "+ ques_title+". At the end of the recording the last word or group of words has benn replaced by a beep. Select the correct option to complete the recording";
        break;
      case 19:
        que = <Highlight {...props} />;
        title = "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.";
        break;
      case 20:
        que = <Dictation {...props} />;
        title = "You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.";
        break;
      default:
        // code block
    }


    let question_numbers = {
      'current': currentQuestion + curr_q + 1,
      'total': questionData.length + curr_q
    };

    return (
      <>
        {!mock_already_complete && redirect_to_pending &&
          <div className="content questionsArea mock-already-completed">
            <Row>
              <Col xs={12}>
                <CardBody>
                  <Row className='testFinish unselectable'>
                    <div className="col-sm-12">
                      <p>
                        <span>This mock is unfinished. Please <Button onClick={() => {this.redirectToMock(this.props.location.state?.id, 1)}} color="primary" size="xs">Click here</Button> to resume this test.</span>
                      </p>
                    </div>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </div>
        }
        {!mock_already_complete && redirect_to_begin &&
          <div className="content questionsArea mock-already-completed">
            <Row>
              <Col xs={12}>
                <CardBody>
                  <Row className='testFinish unselectable'>
                    <div className="col-sm-12">
                      <p>
                        <span>You can't resume this mock. <Button onClick={() => {this.redirectToMock(this.props.location.state?.id, 0)}} color="primary" size="xs">Click here</Button> to begin this test.</span>
                      </p>
                    </div>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </div>
        }
        {mock_already_complete === true &&
          <div className="content questionsArea mock-already-completed">
            <Row>
              <Col xs={12}>
                <CardHeader>
                  <Row>
                    <Col xs={12}>
                      <CardTitle tag="h4">
                        {this.state.title}
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className='testFinish unselectable'>
                    <div className="col-sm-12">
                      <p>
                        <span>Dear user, you've already <strong>FINISHED</strong> this mock. Please attempt some other mock.</span>
                      </p>
                    </div>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </div>
        }
	      {!all_ok &&
	      	<ChromeCheck />
	      }
	      {!mock_already_complete && !redirect_to_pending && check_ok && all_ok &&
	      	<>
	      		{timeOutFlag &&
	      			<Modal isOpen={timeOutFlag} size="sm">
	              <ModalBody>
	                <div>Test stopped due to timeout.</div>
	              </ModalBody>
	              <ModalFooter>
	                <Button color="secondary" className="modalCloseBtn" onClick={this.closeTimeoutModal}>OK</Button>
	              </ModalFooter>
	            </Modal>
	          }
	      		{soundModal &&
	      			<Modal isOpen={soundModal} size="sm">
	              <ModalBody>
	                <div>Please wait for question audio to be finished.</div>
	              </ModalBody>
	              <ModalFooter>
	                <Button color="secondary" className="modalCloseBtn" onClick={this.closeSoundModal}>OK</Button>
	              </ModalFooter>
	            </Modal>
	          }
	        	{modal &&
	        		<Modal isOpen={modal} size="sm">
	        			<ModalBody>
	                <div>{msg}</div>
	              </ModalBody>
	              <ModalFooter>
	                <Button color="secondary" className="modalCloseBtn" onClick={this.toggleYes}>{nextType !== 3 && "Yes"} {nextType === 3 && "Ok"}</Button>
	               	{nextType != 3 &&
	               		<Button color="secondary" className="modalCloseBtn" onClick={this.toggle}>No</Button>
	               	}
	              </ModalFooter>
	            </Modal>
	          }
	      		{typeArr && !resourceTested &&
	      			<ResourceCheck
	      				showTest={this.showTest}
	      				pending={this.props.location.state?.pending}
	      				data={{ 'type': typeArr, 'full': fullArr, 'time': timeArr, 'break': mockBreak }}
	      				cat={5}
	      			/>
	      		}
	        	{!dashboardFlag && !changePhase && resourceTested &&
	        		<div className="content questionsArea">
	              <Row>
	                <Col xs={12}>
	                  <Card>
	                    <CardHeader>
	                      <Row>
	                        <Col xs={6}>
	                          <CardTitle tag="h4">
	                            {this.state.title}
	                          </CardTitle>
	                        </Col>
	                        <Col xs={6}>
	                          <div className="text-right questionTime">
	                            <div className="timeRemaining">
	                              <p><i className="fa fa-clock-o"></i> <span>Time Remaining: {Math.floor(secondCount / 60) +':'+ parseInt(secondCount % 60)}</span></p>
	                            </div>
	                            <div className="item">
	                              {/*<p>Question {currentQuestion + curr_q + 1} of {questionData.length + curr_q}</p>*/}
                                <p>Question {question_numbers.current} of {question_numbers.total}</p>
	                            </div>
	                          </div>
	                        </Col>
	                      </Row>
	                    </CardHeader>
	                    <CardBody>
	                      {title}
	                      <Row className='unselectable'>
  	                      {secondCount === 0 && testIndex < 2 && <div>Time up please restart the Test</div> }
  	                      {/*secondCount !== 0 &&  questionData && que */}
                          {/*secondCount === 0 &&  questionData && que*/ }
                          {
                            this.state.internet
                            ? secondCount >= 0 && questionData && que 
                            : <>
                              <Skeleton count={1} width={"50%"} height={200} containerClassName="skeleton-wrapper__mockrecording" />
                              <Skeleton count={5} width={"100%"} containerClassName="skeleton-wrapper__mocklines" />
                            </>
                          }
                          {secondCount === 0 && testIndex >= 2 && <div>Test is now complete.</div>}
	                      </Row>
	                    </CardBody>
	                  </Card>
	                </Col>
	                <Col xs={12}>
	                	<div className="bottomBtns">
	                  	<Row>
	                  		<Col xs={9}>
	                  			{!dashboardFlag && !saveProcess && !resultId &&
	                  				<Button color="primary" onClick={(e) => this.nextButton(e,2)}  size="xs">Save & Exit</Button>
	                  			}
	                    		{saveProcess &&  !(questionData.length != (currentQuestion+1)) &&
	                    			<Button color="primary"  size="xs">Wait...</Button>
	                    		}
	                  			{/*!dashboardFlag && !submitProcess && secondCount !==0 && !resultId &&  !(questionData.length != (currentQuestion+1)) &&
	                  				<Button color="primary" onClick={(e) => this.nextButton(e,1)}  size="xs">Submit</Button>
	                  			*/}
	                  			{submitProcess &&  !(questionData.length != (currentQuestion+1)) &&
	                  				<Button color="primary"  size="xs">Wait...</Button>
	                  			}

	                  			{submitProcess &&  !(questionData.length != (currentQuestion+1)) &&
	                  				<Button color="primary"  size="xs">Wait...</Button>
	                  			}
	                  			{resultId &&
	                  				<NavLink to={`/admin/result/${resultId}`} >
	                  					<Button color="primary"  size="xs">Show Result</Button>
	                  				</NavLink>
	                  			}
				                  {dashboardFlag &&
				                  	<NavLink to={'/admin/dashboard'} >
				                  		<Button color="primary"  size="xs">Go to Dashboard</Button>
				                  	</NavLink>
				                  }
				                  {timeOutFlag &&
				                  	<div>Test stopped due to timeout</div>
				                  }
	                  		</Col>
			                  <Col xs={3}>
			                    {!dashboardFlag && secondCount !== 0 && !resultId && (questionData.length !== (currentQuestion+1)) && questionData.length !== 0 &&
			                    	<Button color="primary" onClick={(e) => this.nextButton(e, 0)} size="xs">NEXT</Button>
			                    }
                          {!dashboardFlag && !submitProcess && secondCount !==0 && !resultId &&  !(questionData.length != (currentQuestion+1)) &&
                            <Button color="primary" onClick={(e) => this.nextButton(e,1)}  size="xs">Submit</Button>
                          }
			                  </Col>
	                  	</Row>
	                  </div>
	                </Col>
	              </Row>
	            </div>
	          }

	          {dashboardFlag &&
	          	<div className="content questionsArea">
	              <Row>
	                <Col xs={12}>
	                  <Card>
	                    <CardHeader>
	                      <Row>
	                        <Col xs={6}>
	                          <CardTitle tag="h4">
	                            {this.state.title}
	                          </CardTitle>
	                        </Col>
	                      </Row>
	                    </CardHeader>
	                    <CardBody>
	                      <Row className='testFinish unselectable'>
	                        <div className="col-sm-12">
	                          <h4 className="mt-0">{save_and_exit ? 'Test Pending' : 'Submit Activity'}</h4>

                            <p>
                              {save_and_exit && <span>This test is not complete. You can resume this test from <strong>Pending Test area</strong>.</span>}
                              {!save_and_exit && <span>The test is now complete. Your report will be available soon in the <strong>Tests Finished area</strong>.</span>}
                            </p>

	                          <NavLink to={'/admin/dashboard'} > <button className="btn">Continue</button></NavLink>
	                        </div>
	                      </Row>
	                    </CardBody>
	                  </Card>
	                </Col>
	                <Col xs={12}>
	                	<div className="bottomBtns">
		                  <Row>
		                  	<Col xs={12}>
			                  	{dashboardFlag &&
			                  		<NavLink to={'/admin/dashboard'} >
			                  			<Button color="primary"  size="xs">Go to Dashboard</Button>
			                  		</NavLink>
			                  	}
		                  		{timeOutFlag &&
		                  			<div>Test stopped due to timeout</div>
		                  		}
		                		</Col>
		                  </Row>
	                	</div>
	              	</Col>
	            	</Row>
	          	</div>
	          }
	          {changePhase && (typeArr[testIndex] !== 3 || (typeArr[testIndex] === 3 && !waitFlag) ) &&
	          	<div className="content questionsArea">
	              <Row>
	                <Col xs={12}>
	                  <Card>
	                    <CardHeader>
	                      <Row>
	                        <Col xs={6}>
	                          <CardTitle tag="h4">
	                            {this.state.title}
	                          </CardTitle>
	                        </Col>
	                        <Col xs={6}>
	                          <div className="text-right questionTime"></div>
	                        </Col>
	                      </Row>
	                    </CardHeader>
	                    <CardBody>
	                      <Row className='unselectable'>
	                        <div className="col-lg-12">
	                          <div className="timeRemaining">
	                            <p>
	                            	<i className="fa fa-clock-o"></i>
	                            	<span>Time Remaining: {Math.floor(secondCount / 60) +':'+ parseInt(secondCount % 60)}</span>
	                            </p>
	                          </div>
	                     			<h4 className="mt-0">Welcome {store.getState().user.first_name} {store.getState().user.last_name}</h4>
	                      		<div className="col-xs-12">
	                        		<table className="table table-bordered">
                                <tbody>
  			                          <tr>
  			                            <th>Section</th>
  			                            <th>No of Question</th>
  			                            <th>Time Allowed</th>
  			                          </tr>
  			                          <tr>
  			                            <td>{this.getTypeName()}</td>
  			                            <td>{fullArr[testIndex].length}</td>
  			                            <td>{timeArr[testIndex]} minutes</td>
  			                          </tr>
                                </tbody>
	                        		</table>
	                      		</div>
	                    		</div>
	                      </Row>
	                    </CardBody>
	                  </Card>
	                </Col>
	                <Col xs={12}>
	                	<div className="bottomBtns">
		                  <Row>
			                  <Col xs={9}>
			                  	<Button color="primary" onClick={(e) => this.nextSection(e)} size="xs" id="start_next_section">Start Next Section</Button>
			                  </Col>
		                  	<Col xs={3}>
		                  		<Button color="primary" onClick={(e) => this.exitSection(e)}  size="xs" id="exit_section">Save and Exit</Button>
		                  	</Col>
		                  </Row>
		                </div>
		              </Col>
		            </Row>
		          </div>
		        }
						{changePhase && (typeArr[testIndex] == 3 && waitFlag) &&
							<div className="content questionsArea">
		            <Row>
		              <Col xs={12}>
		                <Card>
		                  <CardHeader>
		                    <Row>
		                      <Col xs={6}>
		                        <CardTitle tag="h4">
		                          {this.state.title}
		                        </CardTitle>
		                      </Col>
		                      <Col xs={6}>
		                        <div className="text-right questionTime"></div>
		                      </Col>
		                    </Row>
		                  </CardHeader>
		                  <CardBody>
		                    <Row className='unselectable'>
		                      <div className="col-lg-12">
		                        <div className="timeRemaining">
		                          <p>
		                          	<i className="fa fa-clock-o"></i>
		                          	<span>Time Remaining: {Math.floor(secondCount / 60) +':'+ parseInt(secondCount % 60)}</span>
		                         	</p>
		                        </div>
		                        <h4 className="mt-0">Optional Break</h4>
		                          <h6>You may now take a break up to 10 minutes. If you want to take a break you must leave the testing room.</h6>
		                          <ol>
		                            <li>If you want to take a break raise your hand to get the attention of the Test Administrator.</li>
		                            <li>If you do not want to take a break click on next button below to continue the test.</li>
		                          </ol>
		                          <img src={tenMinutes} />
		                      </div>
		                    </Row>
		                  </CardBody>
		                </Card>
		              </Col>
		              <Col xs={12}>
		              	<div className="bottomBtns">
			                <Row>
			                	<Col xs={9}>
			                		<Button color="primary" onClick={(e) => this.skipWait(e)} size="xs">Skip wait</Button>
			                	</Col>
			                	<Col xs={3}></Col>
			                </Row>
		                </div>
		              </Col>
		            </Row>
		          </div>
		        }
		      </>
				}
			</>
  	);
  }
}

export default Test;
