/*global chrome*/
import React from "react";
import { Link } from "react-router-dom";
import { store, persistor } from "store/index";
import API, { base } from "api.js";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homePage: false,
      staffFlag: false,
      isMainSite: false,
      logoIcon: "",
    };
  }
  componentDidMount() {
    const isMainSite =
      window.location.hostname.split(".").shift() === "www" ? true : false;
    this.setState({ isMainSite: isMainSite });
    window.scrollTo(0, 0);
    if (!isMainSite) {
      var bodyFormData = new FormData();
      bodyFormData.set(
        "subdomain",
        window.location.hostname.split(".").shift()
      );
      API({
        method: "POST",
        url: "get-logo",
        data: bodyFormData,
      })
        .then((data) => {
          if (data.data.data) {
            this.setState({ logoIcon: data.data.data });
          }
        })
        .catch(() => {});
    }
  }

  openStaff = () => {
    this.setState({ staffFlag: !this.state.staffFlag });
  };

  render() {
    return (
      <header className="mainHeader">
        <div className="topLinks">
          <div className="container">
            <div className="row">
              {this.state.isMainSite && (
                <div className="col-sm-12 text-right">
                  <span className="uppercase">
                    EMAIL{" "}
                    <a href="mailto:info@languageacademy.com.au">
                      info@languageacademy.com.au
                    </a>
                  </span>
                  <span className="call uppercase">
                    CALL <a href="tel:61426230061">+61 426230061</a>
                  </span>
                  <span className="register">
                    <i className="fa fa-user" aria-hidden="true"></i>{" "}
                    <Link to="/auth/register">Register</Link>
                  </span>
                  <span className="register">
                    <i className="fa fa-users" aria-hidden="true"></i>{" "}
                    <Link to="/join-us">Careers</Link>
                  </span>
                  <span className="register">
                    <i className="fa fa-building" aria-hidden="true"></i>{" "}
                    <Link to="/franchise">Franchise</Link>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="logoNav">
          <div className="container">
            <div className="row">
              {this.state.isMainSite && (
                <div className="col-sm-2 logo">
                  <Link to="/">
                    <img src={base + "/images/logol.png"} alt="logo-dark" />
                  </Link>
                </div>
              )}
              {!this.state.isMainSite && (
                <div className="col-sm-12 logo">
                  <Link to="/auth/login">
                    <img src={base + this.state.logoIcon} alt="logo-dark" />
                  </Link>
                </div>
              )}
              <div className="col-sm-10 navigation text-right">
                <span className="mobileNav">
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </span>
                <div id="navigation">
                  <span className="close-menu">
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </span>
                  {this.state.isMainSite && (
                    <ul id="mainNav">
                        {/* Top nav bar titles here */}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
