import React from "react";
import Header from "../components/Frontend/Header";
import Footer from "../components/Frontend/Footer";
import CoursesWeOffer from "../components/Frontend/coursesWeOffer";
import FameWall from "../components/Frontend/FameWall";
import Testimonial from "../components/Frontend/Testimonial";
import VideoTuts from "../components/Frontend/VideoTuts";
import WhyLove from "../components/Frontend/WhyLove";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import API from "api.js";
import { setAlert } from "action/index.js";
import { store } from "store/index";
import {
  Modal,
  Button,
  ModalBody,
  Container,
  Col,
  Row,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

// import TiktokPixel from 'tiktok-pixel';
// TiktokPixel.init('CG0870RC77U8JD8FUT4G');
// TiktokPixel.pageView();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexModal: false,
      name: "",
      phone: "",
      email: "",
      center: "",
      process: false,
      taskModal: false,
      task_success_bag: "",
      task_error_bag: "",
      task_name: "",
      task_phone: "",
      task_email: "",
      task_institute: "",
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (store.getState().token ? false : true) {
      setTimeout(() => {
        this.setState({ indexModal: true });
      }, 1000);
    }
    ReactGA.initialize("UA-157984080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init("2474090032742391");
    ReactPixel.init("197333187452630");
    ReactPixel.pageView();
    this.timerFunc();
  }

  timerFunc() {
    /* var countDownDate = new Date("Apr 4, 2021 16:37:52").getTime();

        // Run myfunc every second
        var myfunc = setInterval(function() {

        var now = new Date().getTime();
        var timeleft = countDownDate - now;

        // Calculating the days, hours, minutes and seconds left
        var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        // Result is output to the specific element
        //document.getElementById("days").innerHTML = days + "d "
        //document.getElementById("hours").innerHTML = hours + "h "
        //document.getElementById("mins").innerHTML = minutes + "m "
        //document.getElementById("secs").innerHTML = seconds + "s "
        if (document.querySelectorAll('#timer-id').length > 0) {
            document.getElementById("timer-id").innerHTML= days+' Days, '+hours+':'+minutes+':'+seconds+' Left';
        }*/
    // Display the message when countdown is over
    /*if (timeleft < 0) {
            clearInterval(myfunc);
        }
        });*/
  }

  closeIndexModal = (event) => {
    event.preventDefault();
    this.setState({ indexModal: false });
  };

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  postSubmit = async (e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    e.preventDefault();
    this.setState({ process: true });

    var bodyFormData = new FormData();
    bodyFormData.append("phone", this.state.phone);
    bodyFormData.append("name", this.state.name);
    bodyFormData.append("email", this.state.email);
    bodyFormData.append("center", this.state.center);
    let res = null;
    try {
      res = await API({
        method: "POST",
        url: "/submit-query",
        data: bodyFormData,
      });
    } catch (error) {
      this.setState({ process: false });
      return "l";
    }
    console.log(res);
    if (res.status === 200) {
      this.props.history.push({
        pathname: "/trial-class-thankyou",
        state: { flag: true },
      });
      // store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message,process:false}));
      this.setState({ indexModal: false });
    } else if (res.response.status === 422) {
      this.setState({ error: res.response, process: false });
      let str = "";
      Object.values(this.state.error.data.errors).forEach((val) => {
        str += val[0] + "\n";
      });
      this.setState({ error_bag: str });
    }
  };

  post20taskRequest = async (e) => {
    e.preventDefault();
    if (
      this.state.task_name == "" ||
      this.state.task_phone == "" ||
      this.state.task_email == "" ||
      this.state.task_institute == ""
    ) {
      this.setState({ task_error_bag: "Please fill all fields!" });
    } else {
      this.setState({ task_error_bag: "" });
      var bodyFormData = new FormData();
      bodyFormData.set("name", this.state.task_name);
      bodyFormData.set("phone", this.state.task_phone);
      bodyFormData.set("email", this.state.task_email);
      bodyFormData.set("institute", this.state.task_institute);
      console.log(bodyFormData);
      console.log(this.state);
      let res = null;
      try {
        res = await API({
          method: "POST",
          url: "/task-request-submit-data",
          data: bodyFormData,
        });
      } catch (error) {}
      console.log(res);
      if (res.status === 200) {
        this.setState({ task_success_bag: "Request Submitted Successfully!" });
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        this.setState({ task_error_bag: "Unable to submit request!" });
      }
    }
  };

  showTaskModal = () => {
    this.setState({ taskModal: true });
  };

  closeTaskModal = (event) => {
    event.preventDefault();
    this.setState({ taskModal: false });
  };

  render() {
    return (
      <React.Fragment>
        <Redirect to={"/auth/login"} />
        <Helmet htmlAttributes>
          <title>
            Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching
            Centre In Sydney
          </title>
          <meta
            name="twitter:title"
            content="Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney"
          />
          <meta
            property="og:title"
            content="Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney"
          />
          <meta
            name="description"
            content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au"
          />

          <meta
            name="twitter:description"
            content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au"
          />
          <meta
            property="og:description"
            content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au"
          />
        </Helmet>

        <Modal isOpen={this.state.indexModal} size="lg" id="anouncementPopup">
          <div className="modal-content">
            <div className="modal-header" hidden="">
              <a
                onClick={(e) => {
                  this.closeIndexModal(e);
                }}
                style={{ color: "#93c13e", opacity: "unset" }}
                href="#"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </a>
            </div>
            <div className="modal-body pte_popup_modal_body">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <img
                    className="pte-popup-logo-img"
                    style={{ width: "100px" }}
                    src="/images/logol.png"
                  />
                  <p className="pte_popup_modal_ptag"></p>
                  <div>
                    <h2
                      className="pte_popup_modal_h2tag pte_popup_modal_h2tagl1"
                      style={{
                        textAlign: "center",
                        fontSize: "23px",
                        margin: "30px 0px 0px 0px",
                        padding: "0px",
                        fontWeight: "700",
                      }}
                    >
                      Attend a{" "}
                    </h2>
                    <h2
                      className="pte_popup_modal_h2tag pte_popup_modal_h2tagl2"
                      style={{
                        fontSize: "27px",
                        fontStyle: "italic",
                        margin: "5px 0px",
                        textAlign: "center",
                      }}
                    >
                      <b style={{ color: "#a7ff00" }}>FREE TRIAL CLASS</b>{" "}
                    </h2>
                    <h2
                      className="pte_popup_modal_h2tag pte_popup_modal_h2tagl3"
                      style={{
                        textAlign: "center",
                        fontSize: "23px",
                        margin: "0px",
                        padding: "0px",
                        color: "black",
                      }}
                    >
                      for PTE, NAATI CCL or IELTS with us.
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 pte_popup_modal_seccol">
                  <div className="pte_popup_modal_sec_inner_col">
                    <h4
                      className="pte_popup_modal_h4tag"
                      style={{ textAlign: "center" }}
                    >
                      Book NOW!
                    </h4>
                    <p
                      className="pte_popup_modal_ptag"
                      style={{ textAlign: "center" }}
                    >
                      Submit Your Details Below
                    </p>
                    <p
                      className="pte_popup_modal_ptag"
                      style={{ textAlign: "center" }}
                    >
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </p>
                    <div className="pte-popup-form-start">
                      <form onSubmit={this.postSubmit}>
                        <div className="col-md-12">
                          <input
                            className="ptepop_name form-control"
                            onChange={(e) => {
                              this.changeInput(e);
                            }}
                            value={this.state.name}
                            name="name"
                            placeholder="Name*"
                            type="text"
                          />
                          {this.validator.message(
                            "name",
                            this.state.name,
                            "required"
                          )}
                          <span className="err name-error"></span>
                        </div>

                        <div className="col-md-12">
                          <div
                            className="pte-modal-inputf-gap"
                            style={{ height: "10px" }}
                          ></div>
                          <input
                            className="ptepop_phone form-control"
                            onChange={(e) => {
                              this.changeInput(e);
                            }}
                            value={this.state.phone}
                            name="phone"
                            placeholder="Phone Number*"
                            type="text"
                          />
                          {this.validator.message(
                            "phone",
                            this.state.phone,
                            "required|numeric|phone"
                          )}
                          <span className="err phone-error"></span>
                        </div>

                        <div className="col-md-12">
                          <div
                            className="pte-modal-inputf-gap"
                            style={{ height: "10px" }}
                          ></div>
                          <input
                            className="form-control ptepop_email"
                            name="email"
                            onChange={(e) => {
                              this.changeInput(e);
                            }}
                            value={this.state.email}
                            placeholder="Email Address*"
                            type="email"
                          />
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required|email"
                          )}
                          <span className="err email-error"></span>
                        </div>

                        <div className="col-md-12">
                          <div
                            className="pte-modal-inputf-gap"
                            style={{ height: "10px" }}
                          ></div>
                          <select
                            className="form-control ptepop_email"
                            name="center"
                            onChange={(e) => {
                              this.changeInput(e);
                            }}
                            value={this.state.center}
                          >
                            <option value="">Select center</option>
                            <option>Parramatta</option>
                            <option>Sydney CBD</option>
                            <option>Jalandhar</option>
                            <option>Gurdaspur</option>
                            <option>Amristar</option>
                            <option>Sirsa</option>
                            <option>Sri Ganganagar</option>
                            <option>Kurukshetra</option>
                            <option>Online</option>
                          </select>
                          {this.validator.message(
                            "center",
                            this.state.center,
                            "required"
                          )}
                          <span className="err email-error"></span>
                        </div>

                        <div className="col-md-12 row_before_pte_submit">
                          <div
                            className="pte-modal-inputf-gap"
                            style={{ height: "10px" }}
                          ></div>
                          {!this.state.process && (
                            <a
                              onClick={this.postSubmit}
                              className="btn btn-primary pte_popup_modal_submit"
                            >
                              Submit
                            </a>
                          )}
                          {this.state.process && (
                            <a className="btn btn-primary pte_popup_modal_submit">
                              Wait...
                            </a>
                          )}
                        </div>
                      </form>
                    </div>
                    <div
                      className="pte-modal-inputf-gap"
                      style={{ height: "10px" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {this.state.taskModal && (
          <Modal
            isOpen={this.state.taskModal}
            id="anouncementPopup"
            size="lg"
            className="taskpopup"
          >
            <ModalBody>
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="12" md="12">
                    <button
                      onClick={(e) => {
                        this.closeTaskModal(e);
                      }}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <div className="mobileIcon">
                      <img
                        src="/images/experienced-trainer.png"
                        alt="mobile-img"
                      />
                    </div>
                    <Form action="" className="form" method="">
                      <h3 className="header text-center">
                        Get our portal for your institute
                      </h3>
                      {this.state.task_success_bag && (
                        <Alert color="success">
                          {this.state.task_success_bag}
                        </Alert>
                      )}
                      {this.state.task_error_bag && (
                        <Alert color="warning">
                          {this.state.task_error_bag}
                        </Alert>
                      )}
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Name"
                          type="text"
                          name="task_name"
                          value={this.state.task_name}
                          onChange={(e) => {
                            this.changeInput(e);
                          }}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-mobile" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Phone"
                          type="number"
                          name="task_phone"
                          value={this.state.task_phone}
                          onChange={(e) => {
                            this.changeInput(e);
                          }}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="text"
                          name="task_email"
                          value={this.state.task_email}
                          onChange={(e) => {
                            this.changeInput(e);
                          }}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-badge" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Institute Name"
                          type="text"
                          name="task_institute"
                          value={this.state.task_institute}
                          onChange={(e) => {
                            this.changeInput(e);
                          }}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <br />
                      <Button
                        className="btn-round mb-3"
                        color="warning"
                        onClick={(e) => {
                          this.post20taskRequest(e);
                        }}
                      >
                        Submit
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        )}

        <div className="homePage">
          <Header />
        </div>

        <section className="homeBanner">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 my-auto">
                <h1>
                  Enrol at the <span>Top NAATI & PTE Training Institute</span>{" "}
                  in Australia and nail the exam like a pro
                </h1>
                <h2>
                  Attend a free trial class at our PTE / NAATI CCL coaching
                  <br /> centre in Australia and feel the difference
                </h2>
                <Link to="/auth/register" className="themeBtn">
                  Register
                </Link>
                <Link to="/contact" className="themeBtn blueBtn">
                  Contact us
                </Link>
              </div>
              <div className="col-sm-6">
                <img src="images/bannerImg.png" alt="bannerImg" />
              </div>
            </div>
          </div>
        </section>

        <CoursesWeOffer className="courses" />
        <FameWall />

        <section className="scoredArea">
          <div className="container">
            <div className="row speakingScore">
              <div className="col-sm-6">
                <img src="images/speaking-score.png" alt="speaking-score" />
              </div>
              <div className="col-sm-6 scoreInfo my-auto">
                <img src="images/thums-up.png" alt="thums-up" />
                <h3>
                  Scored Speaking
                  <br /> in real time
                </h3>
                <p>
                  With feedback on all the tasks in speaking section, you can
                  instantly analyse fluency, pronunciation and content.
                </p>
                <Link to="/contact" className="themeBtn">
                  Contact us
                </Link>
              </div>
            </div>
            <div className="row scorecardReport">
              <div className="col-sm-6 scoreInfo my-auto">
                <img src="images/fingers-up.png" alt="fingers-up" />
                <h3>
                  Instant Scorecard Report for scored sectional and full mock
                  test
                </h3>
                <p>
                  {" "}
                  With the smartest AI checker, you will receive instant
                  scorecard including in-depth feedback and analysis. You can
                  easily track your mistakes, score contribution and make the
                  necessary changes.
                </p>
                <Link to="/contact" className="themeBtn">
                  Contact us
                </Link>
              </div>
              <div className="col-sm-6">
                <img src="images/scored-report.png" alt="scored-report" />
              </div>
            </div>
            <div className="row repeatedQues">
              <div className="col-sm-6">
                <img
                  src="images/repeated-questions.png"
                  alt="repeated-questions"
                />
              </div>
              <div className="col-sm-6 scoreInfo my-auto">
                <img src="images/questionMark.png" alt="questionMark" />
                <h3>New Repeated Questions and Predictions </h3>
                <p>
                  Getting access to the PTE monthly predictions with highly
                  repeated questions will make your journey even smoother.
                </p>
                <Link to="/contact" className="themeBtn">
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </section>
        <VideoTuts />
        <WhyLove />
        <Testimonial />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
