import React, { memo, useState, useEffect } from 'react';
import {
	Col
} from 'reactstrap';
import Speech from 'speak-tts';
import FunctionBox from 'components/Question/Speaking/FunctionBox';

import "components/custom.css";

const ReadAloud = memo(function ReadAloud(props) {

	const { strategy, resultMode, testMode, answer } = props;

	const [eval_strategy, setStrategy] = useState(strategy === 1 || strategy === 2 ? strategy : 1);
	const [question, setQuestion] = useState("");
	const [isRecording, setIsRecording] = useState(false);
	const [blobURL, setBlobUrl] = useState("");
	const [micPermitted, setMicPermitted] = useState(null);

	const normalStrategy = () => {
		setStrategy(1);
		props.setStrategy(1);
	}

	const oneLineStrategy = () => {
		setStrategy(2);
		props.setStrategy(2);
	}


	const selectSpan = (e, item) => {
		if (!testMode) {
			const speech = new Speech();
			speech.speak({ text: item });
		}
	}

	const updateRecordingStatus = (status) => {
		setIsRecording(status);
	}

	const blobUrlSet = (url) => {
		setBlobUrl(url);
	}


	/**
	 * if true.
	 * this question recording is finished by user and ready to submit.
	 */
	const submitReadySet = (data) => {
		blobUrlSet(data.blobUrl);
		const data_to_send = {
			data: data.file,
			duration: data.duration,
			audio_text: data.transcript,
			strategy: eval_strategy
		};

		if (testMode) { // Test
			props.nextQuestion(data_to_send);
		} else { // Practice
			props.submit(data_to_send, true);
			props.showResult();
		}
	}

	useEffect(() => {
		// microphone permissions
		navigator.mediaDevices.getUserMedia({ audio: true })
		.then(() => {
			setMicPermitted(true);
		})
		.catch(() => {
			setMicPermitted(false);
		});

		// filter and set question.
		const filtered_question = props.data.question.replace(/<span[^>]*>?/g, ' __').replace(/<[^>]*span>?/g, '__');
		setQuestion(filtered_question);
	}, []);


	useEffect(() => {
		if (props.restart) {
			props.setRestart();
		}
	}, [props.restart]);


	// /**
	//  * For Test.
	//  */
	// useEffect(() => {
	// 	if (props.stopRecording && testMode) {

	// 	}
	// }, [props.stopRecording]);
	
	
	return (
		<>
			{micPermitted === false &&
				<div className="alert alert-danger">No Microphone permissions granted. Please grant microphone permissions and reload the page.</div>
			}
			{micPermitted && question !== "" &&
				<>
					<Col xs={12}>
			        	<h5><b></b></h5>
			        </Col>

			        <Col xs={12} className={eval_strategy === 1 ? '' : 'three-border'}>
			        	{eval_strategy === 2 &&
							<span className="border-label">ONE LINE STRATEGY</span>
						}

						<div className="col-lg-12 text-center">
							{!resultMode &&
								<>
									<div className="scoring-mode">
										<button className={`btn ${eval_strategy === 1 ? 'btnActive' : ''}`} onClick={() => normalStrategy()}>Normal</button>
										<button className={`btn ${eval_strategy === 2 ? 'btnActive' : ''}`} onClick={() => oneLineStrategy()}>One Line Strategy</button>
									</div>
									<FunctionBox
										testMode={testMode}
										answer={answer}
										blobURL={blobURL}
										blobUrlSet={blobUrlSet}
										submitReadySet={submitReadySet}
										stopRecording={props.stopRecording}
									/>
								</>
							}
							<Col xs={12}>
								<Audio 
									testMode={testMode}
									recording={isRecording}
									blobURL={blobURL}
								/>
							</Col>
						</div>
			        </Col>

			        <Col xs={12} className={eval_strategy === 1 ? '' : 'border-three'}>
			        	<h5>
			        		{question.split(' ').map(function(item, index) {
			        			return <React.Fragment key={index}><span className={(testMode) ? "" : "speach-text"} onClick={(e) => selectSpan(e, item)}>{item}</span>&nbsp; </React.Fragment>;
			        		}, this)}
			        	</h5>
			        </Col>
			    </>
		    }
		</>
	);
});
export default ReadAloud;



/**
 * Audio Box.
 */
function Audio(props) {

	const [testMode, setTestMode] = useState(false);
	const [blobUrl, setBlobUrl] = useState("");

	
	useEffect(() => {
		setTestMode(props.testMode);
	}, []);

	useEffect(() => {
		setBlobUrl(props.blobURL);
	}, [props.blobURL]);


	return (
		<>
			{blobUrl && !testMode &&
				<audio src={blobUrl} controls="controls" />
			}
		</>
	);
}