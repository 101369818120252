import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,Button,Collapse
} from "reactstrap";
import { Link } from "react-router-dom";
import API from "api.js";
import { setUser} from 'action/index.js';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};

class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
      status:{},
      questionList:true,
      questionData:[],
      qArr: [],
      qId: null,
      redirect: false
    };
  }

  getQuestionType = (type) =>{
    type = parseInt(type);
    let title = "";
    switch(type) {
      case 1:
        title = "Read Aloud";
        break;
      case 2:
        title = "Repeat Sentence"
        break;
      case 3:
        title = "Describe Image";
        break;
      case 4:
        title = "Respond to Situation";
        break;
      case 5:
        title = "Answer Short Questions";
        break;
      case 6:
        title = "Summarize written text";

        break;
      case 7:
        title = "Write Email";

        break;
      case 8:
        title = "Multiple Type, Single Answer ";
        break;
      case 9:
        title = "Multiple Type, Double Answer ";
        break;
      case 10:
        title = "Reorder Paragraph ";
        break;
      case 11:
        title = "Reading Fill in the Blanks ";
        break;
      case 12:
        title = "Fill in the Blanks Reading & Writing ";
        break;
      case 13:
        title = "Summarize Spoken text ";
        break;
      case 14:
        title = "MCQ Single answer ";
        break;
      case 15:
        title = "MCQ multiple answer ";
        break;
      case 16:
        title = "Listening Fill In the Blanks ";
        break;
      case 17:
        title = "Highlight correct summary ";
        break;
      case 18:
        title = "Select Missing Word ";
        break;
      case 19:
        title = "Highlight Incorrect word ";
        break;
      case 20:
        title = "Write from Dictation ";

        break;
      default:
        // code block
    }
    return title;
  }
   componentDidMount() {

      let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
      let all_ok1 = (isChrome)?true:false;

      navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({all_ok1:all_ok1});
      },
      () => {
        this.setState({all_ok1:false});
        console.log('Permission Denied');

      },
    );
    API({
        method: 'GET',
        url: 'practiceDetail'
      }).then(data => {
        let obj = data.data.data
        console.log(obj);
        let status = {};
        Object.keys(obj).map((val, key) => {
          status[val] = false;
        });
        console.log(status)
        this.setState({data:obj,status:status});
    }).catch(()=>{

      });

  }

  openQuestionList = (type,date) =>{
    API({
        method: 'GET',
        url: 'single/practiceDetail?type='+type+'&date='+date
      }).then(data => {
        //let obj = data.data.data
        console.log(data);
        const qArr = data.data.data.filter((val) => {
          return val.question !== null;
        })
        console.log(qArr);
        this.setState({qArr: qArr});
        this.setState({questionData:data.data.data,questionList:false});

    }).catch(()=>{

      });
  }

  backbtn = () =>{
    this.setState({questionList:true});
  }

  openTab = (e,val,f) =>{
    let obj = {...this.state.status, [val]:f };
    this.setState({status: obj});
    console.log(obj)
  }

  render() {

    let i = 0;
    return (
      <>
        {/* {this.state.redirect} */}
        {true && <div className="content">
                  <Row>
                    <Col xs={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">Question Practiced - <span>(past 10 days)</span></CardTitle>
                        </CardHeader>
                        <CardBody>
                          {this.state.questionData && !this.state.questionList && [1].map((val, key) => {
                               console.log(this.state.status[val])
                               return (

                                <React.Fragment>
                                <button className="btn btn-success" onClick={(e)=>{this.backbtn()}}>Back</button>
                                <div className="practice-div"></div>
                                <Card><CardBody>
                                <table className="table table-bordered">
                                <tr>
                                  <th>Question Title</th>
                                  <th>Question Type</th>
                                  <th>Action</th>
                                </tr>
                                {this.state.questionData.map((v,k)=>{
                                    if (v.question) {
                                      return (<tr>
                                      <td>
                                        {v.question.q_title}
                                      </td>
                                      <td>
                                      {this.getQuestionType(v.type)}
                                      </td>
                                      <td>

                                      {/* {<Link to={`/admin/practice/0/${v.question.subcategory_id}/${v.question.id}/1/all/all/all?view=1`}>Open</Link>} */}
                                      {/* {
                                      <Link 
                                        to={{
                                          pathname: `/admin/practice-hist/0/${v.question.subcategory_id}/${v.question.id}/1/all/all/all?view=1`,
                                          state: {
                                            questions: this.state.qArr,
                                            index: k
                                          }
                                        }}
                                      >Open
                                      </Link>} */}
                                      <button 
                                        pathName={`/admin/practice-hist/0/${v.question.subcategory_id}/${v.question.id}/1/all/all/all?view=1`} 
                                        className="btn btn-primary"
                                        index={k}
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname: `/admin/practice-hist/0/${v.question.subcategory_id}/${v.question.id}/1/all/all/all?view=1`,
                                            state: {
                                              questions: this.state.qArr,
                                              index: k
                                            }
                                          })
                                        }}
                                      >
                                        Open
                                      </button>
                                      </td>
                                      </tr>);
                                    }



                                })}</table></CardBody></Card></React.Fragment>)

                            })
                          }
                          {this.state.data && this.state.questionList && Object.keys(this.state.data).map((val, key) => {
                               console.log(this.state.status[val])
                               return (

                                <React.Fragment>

                                <div className="practice-div"><Button onClick={(e)=>{ this.openTab(e,val,(this.state.status[val])?false:true) }} >{val} {(this.state.status[val])?<i class="fa fa-angle-down"></i>:<i class="fa fa-angle-up"></i>}</Button></div>
                                <Collapse isOpen={this.state.status[val]}><Card><CardBody>
                                <table className="table table-bordered">
                                <tr>
                                  <th>Question Type</th>
                                  <th>Question Count</th>
                                  <th>Action</th>
                                </tr>

                                {Object.keys(this.state.data[val]).map((v,k)=>{

                                    return (<tr>
                                      <td>
                                      {this.getQuestionType(v)}
                                      </td>
                                      <td>
                                      {Object.keys(this.state.data[val][v]).length}
                                      </td>
                                      <td>
                                      <button className="btn btn-success" onClick={(e)=>{this.openQuestionList(v,val)}}>Open</button>
                                      {/*<Link to={`/admin/practice/0/${v.question.subcategory_id}/${v.question.id}/1/all/all/all?view=1`}>Open</Link>*/}
                                      </td>
                                      </tr>);


                                })}</table></CardBody></Card></Collapse></React.Fragment>)

                            })
                          }

                        </CardBody>
                      </Card>
                    </Col>

                  </Row>
                </div>}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
