export function setToken(val) {
   return {
      type: 'SETTOKEN',val: val
   }
}

export function setLogo(val) {
   return {
      type: 'SETLOGO',val: val
   }
}

export function setAlert(val) {
   return {
      type: 'SETALERT',val: val
   }
}

export function setLoader(val) {
   return {
      type: 'SETLOADER',val: val
   }
}

export function isMain(val) {
   return {
      type: 'ISMAIN',val: val
   }
}

export function isPortal(val) {
   return {
      type: 'ISPORTAL',val: val
   }
}

export function setUser(val) {
   return {
      type: 'SETUSER',val: val
   }
}

export function setSub(val) {
   return {
      type: 'SETSUB',val: val
   }
}

export function isModalShown(val) {
   return {
      type: 'ISMODALSHOWN',val: val
   }
}

export function intStatus(val) {
   return {
      type: 'INTSTATUS',val: val
   }
}

export function expVidPopup(val) {
   return {
      type: 'EXPVIDPOPUP',val: val
   }
}

export function setUserType(val) {
   return {
      type: 'USERTYPE', val: val
   }
}

export function setNotifications(val) {
   return {
      type: 'NOTIFICATIONS', val: val
   }
}
