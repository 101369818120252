import React from 'react';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,Button,
  Table,
  TabContent, TabPane, Nav, NavItem,CardText,NavLink as NavLink1
} from "reactstrap";
import ReactGA from 'react-ga';

import API,{base} from "api.js";
import 'react-daypicker/lib/DayPicker.css';
import DayPicker from 'react-daypicker';
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          arr:{1 : [1,2,3,4,5],2 :[6,7],3 :[8,9,10,11,12],4: [13,14,15,16,17,18,19,20],5:[21,22,23,24,25]},
          group:[],
          mock:{},
          result:false,
          remarks:[],
          activeTab:1,
          user:false,
          f:true,
          date:new Date(),
          isDate:false,
          selectedDate:"",
          activeTab:1,
          edit:false,
          feedbackArr:{1:false,2:false,3:false,4:false,5:false,6:false,7:false,8:false,9:false,10:false,11:false,12:false,13:false,14:false,15:false,16:false,17:false,18:false,19:false,20:false},
          countArr:{'que_1':0,'que_2':0,'que_3':0,'que_4':0,'que_5':0,'que_6':0,'que_7':0,'que_8':0,'que_9':0,'que_10':0,'que_11':0,'que_12':0,'que_13':0,'que_14':0,'que_15':0,'que_16':0,'que_17':0,'que_18':0,'que_19':0,'que_20':0,'que_21':0,'que_22':0,'que_23':0,'que_24':0,'que_25':0},
          categoryArr:{1:0,2:0,3:0,4:0,5:0},
          dateArr:[],
          dateSelect:'',
          isTask:true
        
        };
      }
    componentDidMount() {
        API({
            method: 'GET',
            url: 'mock/daily-report'
          }).then(data => {
            let countArr = {'que_1':0,'que_2':0,'que_3':0,'que_4':0,'que_5':0,'que_6':0,'que_7':0,'que_8':0,'que_9':0,'que_10':0,'que_11':0,'que_12':0,'que_13':0,'que_14':0,'que_15':0,'que_16':0,'que_17':0,'que_18':0,'que_19':0,'que_20':0,'que_21':0,'que_22':0,'que_23':0,'que_24':0,'que_25':0};
            let categoryArr = {1:0,2:0,3:0,4:0,5:0};
            let task = false;
            if (data.data.task) {
              countArr = data.data.task;
              task = true
            }
            let group = data.data.data.reduce((r, a) => {
               r[a.type] = [...r[a.type] || [], a];
               return r;
              }, {});
            this.setState({isTask:task,dateArr:data.data.task_arr,categoryArr:{...this.state.categoryArr,...data.data.mock},countArr:countArr,selectedDate:data.data.date,group:group,result:data.data.data,remarks:data.data.remarks,user:data.data.user});
        }).catch(()=>{
          
        });
    }

  getQuestionType = (type) =>{
    let title = "";
    switch(type) {
      case 1:
        title = "Read Aloud";
        break;
      case 2:
        title = "Repeat Sentence"
        break;
      case 3:
        title = "Describe Image";
        break;
      case 4:
        title = "Respond to Situation";
        break;
      case 5:
        title = "Answer Short Questions";
        break;
      case 6:
        title = "Summarize written text";
        
        break;
      case 7:
        title = "Write Email";
        
        break;
      case 8:
        title = "Multiple Type, Single Answer ";
        break;
      case 9:
        title = "Multiple Type, Double Answer ";
        break;
      case 10:
        title = "Reorder Paragraph ";
        break;
      case 11:
        title = "Reading Fill in the Blanks ";
        break;
      case 12:
        title = "Fill in the Blanks Reading & Writing ";
        break;
      case 13:
        title = "Summarize Spoken text ";
        break;
      case 14:
        title = "MCQ Single answer ";
        break;
      case 15:
        title = "MCQ multiple answer ";
        break;
      case 16:
        title = "Listening Fill In the Blanks ";
        break;
      case 17:
        title = "Highlight correct summary ";
        break;
      case 18:
        title = "Select Missing Word ";
        break;
      case 19:
        title = "Highlight Incorrect word ";
        break;
      case 20:
        title = "Write from Dictation ";
        
        break;
      case 21:
        title = "Speaking Mock ";
        
        break;
      case 22:
        title = "Writing Mock ";
        
        break;
      case 23:
        title = "Reading Mock ";
        
        break;
      case 24:
        title = "Listening Mock";
        
        break;
      case 25:
        title = "Full Mock ";
        
        break;
      default:
        // code block
    }
    return title;
  }

  getMockType = (type) =>{
    let title = "";
    switch(type) {
      case 1:
        title = "Speaking Mock Test";
        break;
      case 2:
        title = "Writing Mock Test"
        break;
      case 3:
        title = "Reading Mock Test";
        break;
      case 4:
        title = "Listening Mock Test";
        break;
      case 5:
        title = "Full Mock Test";
        break;
    }
    return title;
  }

  calculateScore = (arr) => {
    let score = 0;
    let from = 0;
    arr.map((v)=>{
        v.score.map((val)=>{
          score += val.score;
          from += val.from;
        });
    });
    let per = score*100/from;
    return per.toFixed(2);
  }
  calculateRemarks = (arr) => {
    let content_sc = 0;
    let content_fr = 0;
    let overall_sc = 0;
    let overall_fr = 0;
    let pro_sc = 0;
    let pro_fr = 0;
    let score = 0;
    let fluency_sc = 0;
    let fluency_fr = 0;
    let grammar_sc = 0;
    let grammar_fr = 0;
    let vocab_sc = 0;
    let vocab_fr = 0;
    let form_sc = 0;
    let form_fr = 0;
    let spelling_sc = 0;
    let spelling_fr = 0;
    let struct_sc = 0;
    let struct_fr = 0;
    let ling_sc = 0;
    let ling_fr = 0;
    let from = 0;
    let type = ""; 
    arr.map((v)=>{
        console.log(v)
        type = v.type;
        v.score.map((val)=>{
          if (val.type ===1) {
            fluency_sc += val.score;
            fluency_fr += val.from;
          }
          if (val.type ===0) {
            content_sc += val.score;
            content_fr += val.from;
          }
          if (val.type ===2) {
            pro_sc += val.score;
            pro_fr += val.from;
          }
          if (val.type ===3) {
            overall_sc += val.score;
            overall_fr += val.from;
          }if (val.type ===4) {
            grammar_sc += val.score;
            grammar_fr += val.from;
          }if (val.type ===5) {
            form_sc += val.score;
            form_fr += val.from;
          }if (val.type ===6) {
            vocab_sc += val.score;
            vocab_fr += val.from;
          }if (val.type ===7) {
            spelling_sc += val.score;
            spelling_fr += val.from;
          }if (val.type ===8) {
            struct_sc += val.score;
            struct_fr += val.from;
          }if (val.type ===9) {
            ling_sc += val.score;
            ling_fr += val.from;
          }
          score += val.score;
          from += val.from;
        });
    });
    let newArr = [(content_sc*100)/content_fr, (fluency_sc*100)/fluency_fr,(pro_sc*100)/pro_fr,(overall_sc*100)/overall_fr, (grammar_sc*100)/grammar_fr,(form_sc*100)/form_fr,(vocab_sc*100)/vocab_fr ,(spelling_sc*100)/spelling_fr, (struct_sc*100)/struct_fr, (ling_sc*100)/ling_fr ]
    //console.error(newArr)
    let remarkAr ="";
    newArr.map((v,k)=>{
      if (!isNaN(v)) {
        let mapArr = this.getScoreType(v,type,k);
        // console.log('mapArr')
        // console.log(mapArr)
        if (![8,9].includes(parseInt(k))) {
          remarkAr += ' '+(mapArr.length >0 )?'<strong>'+this.remarks(k,type)+' </strong>'+mapArr[0].remarks+' <br/>':"";  
        }
        
      }
      
    });

    return remarkAr;
  }

  getScoreType =(per,type,scType)=>{
    return this.state.remarks.filter((a)=>{
      // console.log((a.min <= per) && (a.max >= per) && a.type == type && a.score_type == scType)
      return (a.min <= per) && (a.max >= per) && a.type == type && a.score_type == scType;
    });
  }

  toggle = tab => {
    
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab});
    }
  }

    remarks = (type,qType) => {
      let str="";
      if (qType === 5) {
        return "";
      }
      switch(type) {
        case 0:
          str ="Content : ";
          break;
        case 1:
          str ="Fluency : ";
          break;
        case 2:
          str ="Pronunciation : ";
          break;
        case 3:
          str ="";
          break;
        case 4:
          str ="Grammar :";
          break;
        case 5:
          str ="Form : ";
          break;
        case 6:
          str ="Vocabulary : ";
          break;
        case 7:
          str ="Spelling : ";
          break;
        case 8:
          str ="Linguistic range : ";
          break;
        case 9:
          str ="Structure : ";
          break;
        default:
          str = "";
      }
      return str;
      
    }

  getTime = (arr) => {
    let time = 0;
    arr.map((v)=>{
        time += v.q_time;
    });
    return (time > 59)?`${Math.floor(time/60)} minutes ${Math.floor(time%60)} sec`:`${time} sec`;
  }

  getUnattempted = (arr) => {
    let c = 0;
    arr.map((v)=>{
      if (v.not_attempted == 0) {
          c += 1;
      }
        
    });
    return c;
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    if (Number.isInteger(parseInt(value)) && parseInt(value) >= 0) {
      this.setState({ countArr : {...this.state.countArr,[name]:+value} });
    }
  };

  changeInputSelect = (e) => {
    let { name, value } = e.target;
    
    this.setState({ [name] : value });
  };

  feedbackAction =(index,flag) =>{
    this.setState({feedbackArr:{...this.state.feedbackArr,[index]:flag}});
  }

  clickSave = () => {
    console.error(this.state.countArr)
    API({
            method: 'POST',
            url: 'save-task',
            data:{...this.state.countArr,date:this.state.selectedDate},
          }).then(data => {
            console.log(data)
            
            this.setState({edit:false});
        }).catch(()=>{
          
        });
  }

  clickEdit = () => {
                
    this.setState({edit:true});

  }

  changeDate = (date) =>{
    this.setState({date:date});
    var fullDate = new Date(date);
    var twoDigitMonth = fullDate.getMonth()+1 + "";
    if (twoDigitMonth.length == 1)
        twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1)
        twoDigitDate = "0" + twoDigitDate;
    var currentDate = fullDate.getFullYear()+'-'+twoDigitMonth+'-'+twoDigitDate ;

    API({
            method: 'GET',
            url: 'mock/daily-report?date='+currentDate
          }).then(data => {
            let countArr = {'que_1':0,'que_2':0,'que_3':0,'que_4':0,'que_5':0,'que_6':0,'que_7':0,'que_8':0,'que_9':0,'que_10':0,'que_11':0,'que_12':0,'que_13':0,'que_14':0,'que_15':0,'que_16':0,'que_17':0,'que_18':0,'que_19':0,'que_20':0,'que_25':0,'que_21':0,'que_22':0,'que_23':0,'que_24':0};
            let task= false;
            if (data.data.task) {
              task = true;
              countArr = data.data.task;
            }
            let categoryArr = {1:0,2:0,3:0,4:0,5:0};
            let group = data.data.data.reduce((r, a) => {
               r[a.type] = [...r[a.type] || [], a];
               return r;
              }, {});
            this.setState({isTask:task,dateArr:data.data.task_arr,categoryArr:{...categoryArr,...data.data.mock},edit:false,countArr:countArr,selectedDate:data.data.date,group:group,result:data.data.data,remarks:data.data.remarks,user:data.data.user,isDate:false});
        }).catch(()=>{
          
        });
  }

  saveAsSelectedDate = () =>{
    let value =""
    if (this.state.dateSelect) {
      value = this.state.dateSelect;
    }else{
      value = this.state.dateArr[0];
    }
    API({
            method: 'GET',
            url: 'mock/daily-report?setDate='+value+'&newDate='+this.state.selectedDate
          }).then(data => {
            let countArr = {'que_1':0,'que_2':0,'que_3':0,'que_4':0,'que_5':0,'que_6':0,'que_7':0,'que_8':0,'que_9':0,'que_10':0,'que_11':0,'que_12':0,'que_13':0,'que_14':0,'que_15':0,'que_16':0,'que_17':0,'que_18':0,'que_19':0,'que_20':0,'que_25':0,'que_21':0,'que_22':0,'que_23':0,'que_24':0};
            if (data.data.task) {
              countArr = data.data.task;
            }
            let categoryArr = {1:0,2:0,3:0,4:0,5:0};
            let group = data.data.data.reduce((r, a) => {
               r[a.type] = [...r[a.type] || [], a];
               return r;
              }, {});
            this.setState({isTask:true,dateArr:data.data.task_arr,categoryArr:{...categoryArr,...data.data.mock},edit:false,countArr:countArr,selectedDate:data.data.date,group:group,result:data.data.data,remarks:data.data.remarks,user:data.data.user,isDate:false});
        }).catch(()=>{
          
        });
  }
 render(){   
  
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Top PTE Training Institute</title>
        </Helmet>
    <div className="content dailyTask">
                  <Row>
                    <Col xs={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">Daily Task</CardTitle>
                          
                        </CardHeader>
                        <CardBody>
                          <div className="dailyTaskouter">
                        <h6><b>Total Question Attempted - {this.state.result.length}</b></h6>
                <h6><b>Selected Date - {this.state.selectedDate}</b></h6>
                <div className="row">
                  <div className="col-md-6">
                    <Button onClick={()=>{this.setState({isDate:!this.state.isDate});}}>Choose Date</Button>
                    {this.state.isDate && <DayPicker onDayClick={this.changeDate} active={this.state.date} />}
                  </div>

                  {this.state.dateArr.length > 0 && !this.state.isTask && <div className="col-md-4">
                    <label>Save task same as selected date</label>
                    <select name ="dateSelect" onChange={(e)=>{this.changeInputSelect(e);}} className="form-control">
                     {this.state.dateArr.map((v,k)=>{
                       return (<option value={v} key={k}> {v} </option>);
                     })}
                    </select>
                    
                  </div>}
                  {this.state.dateArr.length > 0 && !this.state.isTask && <div className="col-md-2">
                    
                    <input type="button" onClick={this.saveAsSelectedDate} className="form-control bg-primary mt-4 text-white" value="Save" />
                    
                  </div>}
                </div>

                
            <div className="row mt-3">
              
              <div className="col-md-12">
                  <Nav className="nav-pills-primary nav-pills-icons justify-content-center"
                            pills
                            role="tablist" tabs>
                  <NavItem>
                    <NavLink1
                    className={(this.state.activeTab === 1)?'active':''}
                    onClick={() => { this.toggle(1); }}
                  >
                    Speaking
                  </NavLink1>
                </NavItem>
                <NavItem>
                  <NavLink1
                    className={(this.state.activeTab === 2)?'active':''}
                    onClick={() => { this.toggle(2); }}
                  >
                    Writing
                  </NavLink1>
                </NavItem>
                <NavItem>
                  <NavLink1
                    className={(this.state.activeTab === 3)?'active':''}
                    onClick={() => { this.toggle(3); }}
                  >
                    Reading
                  </NavLink1>
                </NavItem>
                <NavItem>
                  <NavLink1
                    className={(this.state.activeTab === 4)?'active':''}
                    onClick={() => { this.toggle(4); }}
                  >
                    Listening
                  </NavLink1>
                </NavItem>

                {/*<NavItem>
                  <NavLink1
                    className={(this.state.activeTab === 5)?'active':''}
                    onClick={() => { this.toggle(5); }}
                  >
                    Mock
                  </NavLink1>
                </NavItem>*/}
                
              </Nav>
            </div>
            <div className="col-md-12">
              <Table >
                <thead className="text-primary">
                  <tr>
                    
                          <th className="">
                            Title
                          </th>
                          <th className="">
                            Target
                          </th>
                          <th className="">
                            Attempted
                          </th>
                          <th className="">
                            Feedback
                          </th>
                          
                  </tr>
                </thead>
                <tbody>
                  {this.state.edit && this.state.arr[this.state.activeTab].map((val,key)=>{
                                      return (
                                        <tr>
                                          <td>{this.getQuestionType(parseInt(val))}</td>
                                          <td><input type="number" onChange={(e) => { this.changeInput(e)} } name={`que_${val}`} value={this.state.countArr[`que_${val}`]}  /></td>
                                          
                                          {[21,22,23,24,25].indexOf(val) === -1 && <td>{(Object.keys(this.state.group).includes(''+val))?this.state.group[val].length:'0'}</td>}
                                          {[21,22,23,24,25].indexOf(val) !== -1 && <td>{(Object.keys(this.state.categoryArr).includes(''+(val-20)))?this.state.categoryArr[(val-20)]:'0'}</td>}
                                          <td>-</td>
                                        </tr>
                                        );
                                    })}
                  {!this.state.edit && this.state.arr[this.state.activeTab].map((val,key)=>{
                    return (
                    <React.Fragment key={key}>
                      <tr>
                        <td>
                          {[21,22,23,24,25].indexOf(val) === -1 && <Link to={'/admin/questionList/0/'+parseInt(val)} >{this.getQuestionType(parseInt(val))}</Link>}
                          {[21,22,23,24,25].indexOf(val) !== -1 && <Link to={'/admin/mock'} >{this.getQuestionType(parseInt(val))}</Link>}
                        </td>
                        { <td>{(this.state.countArr[`que_${val}`] !== 0)?this.state.countArr[`que_${val}`]:"N/A"}</td>}
                        
                        {[21,22,23,24,25].indexOf(val) === -1 && <td>{(Object.keys(this.state.group).includes(''+val))?this.state.group[val].length:'0'}</td>}
                        {[21,22,23,24,25].indexOf(val) !== -1 && <td>{(Object.keys(this.state.categoryArr).includes(''+(val-20)))?this.state.categoryArr[(val-20)]:'0'}</td>}
                        <td>
                          {[21,22,23,24,25].indexOf(val) === -1 && !this.state.feedbackArr[val] && <a href="javaScript:void(0)" onClick={(e)=>{this.feedbackAction(val,true)}}>View</a>}
                          {[21,22,23,24,25].indexOf(val) === -1 && this.state.feedbackArr[val] && <a href="javaScript:void(0)" onClick={(e)=>{this.feedbackAction(val,false)}}>Hide</a>}
                          {[21,22,23,24,25].indexOf(val) !== -1 && <Link to='/admin/mock-result' >View</Link>}
                        </td>
                      </tr>
                      {this.state.feedbackArr[val] && <tr>
                                        <td colSpan="4">
                                          {(Object.keys(this.state.group).includes(''+val)) && <p dangerouslySetInnerHTML={{__html: this.calculateRemarks(this.state.group[val])}}></p>}
                                          {(!Object.keys(this.state.group).includes(''+val)) && <p >N/A</p>}
                                        </td>
                                      </tr>}
                      </React.Fragment>
                      );
                  })}
                </tbody>
              </Table>
              {this.state.edit && <button className="btn" onClick={this.clickSave}>Save</button>}
              {!this.state.edit && <button className="btn" onClick={this.clickEdit}>Edit</button>}
              <br/>
            <br/>
            <br/>
            </div>

                {false && Object.keys(this.state.group).length > 0 && <h3>Feedback</h3>}
                <div className="col-sm-12 feedbackCntnt">
                    {false && Object.keys(this.state.group).map((val, key) => {
                          return (

                            <div class="remarksBreakdown">
                                <h5>{this.getQuestionType(parseInt(val))}</h5>
                                <p><b>Question Attempted</b> - {this.state.group[val].length}</p>
                                <p><b>Score Percentage</b> - {this.calculateScore(this.state.group[val])} %</p>
                                 <p> <p dangerouslySetInnerHTML={{__html: this.calculateRemarks(this.state.group[val])}}></p></p>                       
                            </div>
                        );  
                        
                        
                      })}
                    
                </div>
            </div>
            </div>
            </CardBody>
            </Card>
            </Col>
            </Row>
            </div>
    </React.Fragment>);
    }
}

export default Home;