import React from "react";
import UserDetails from "components/Dashboard/Sidebar/UserDetails.jsx";
import Menu from "components/Dashboard/Sidebar/Menu.jsx";

export default function Sidebar(props) {
	return (
		<>
			<div className="leftSidebar">
				<div className="sidebar-fixed">
					<UserDetails />
					<Menu />

					<br />
					<div class="col d-flex justify-content-center mb-4 mt-4">
						<span class="sheen-gold pte-core__title" style={{fontSize: "12px", textAlign: "center"}}>PTE CORE</span>
					</div>
				</div>
			</div>
		</>
	);
}