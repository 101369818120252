import { s3base } from "api";
import Image from "components/Image/Image";
import ModalSkeleton from "components/PopUps/ModalSkeleton";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Introduction = ({ title, video }) => {
  const [modelStatus, setModelStatus] = useState(false);
  const history = useHistory();

  const modelHandler = () => {
    if (video[0].link) {
      history.push("/admin/video/" + video[0].id);
      return;
    }
    setModelStatus((prevState) => !prevState);
  };

  let vidPopup;
  if (!video[0].link) {
    vidPopup = (
      <ModalSkeleton
        status={modelStatus}
        toggle={modelHandler}
        handleClick={() => {
          history.push("/admin/plans");
        }}
        btnText="Buy Plan"
        message="Please subscribe to our gold plan to see Secret Strategy Videos"
        isSmall={false}
      />
    );
  }

  return (
    video.length > 0 && (
      <Row className="strategyIntrOuter">
        <Col xs={12}>
          <h2>{title}</h2>
          <div className="introVideOuter">
            <div className="introImgOuter">
              <div onClick={modelHandler}>
                <Image path={s3base + video[0].thumbnail} alt="" classes="" />
                <div className="introTitle">{video[0].title}</div>
              </div>
            </div>
            <div className="introCntnt">
              <h2>
                PTE Academic
                <br /> Full Video Course
              </h2>
              <h3>by Varun Dhawan</h3>
            </div>
          </div>
        </Col>
        {vidPopup}
      </Row>
    )
  );
};

export default Introduction;
