import React from "react";
import { Link } from "react-router-dom";

export default function Practiced(props) {

	const { count, days } = props;
	return (
		<div className="statBox sheen-gold row mx-0 mt-3 align-items-center">
			<div className="col pl-0 questionsPracticed d-flex flex-column text-center">
				<div className="sheen-gold__text quesPrac">
					<span>Questions Practiced</span>
					<span className="practicedValue">{count !== undefined ? count : 0}</span>
				</div>
			</div>
			<div className="col daysPracticed d-flex flex-column text-center align-items-center py-md-3">
				<span>Days Practiced</span>
				<span className="daysValue">{days !== undefined ? days : 0}</span>
				<p className="mt-auto mb-0 text-center">
					<Link to="/admin/practice-detail">
						<small className="fw-medium cursor-pointer passive-link text-white">See History</small>
					</Link>
				</p>
			</div>
			<div className="col-auto goalsColumn pl-md-4 pr-md-0 d-flex flex-column text-center">
				<Link to="monthly-prediction">Monthly Predictions</Link>
				<Link to="daily-feedback" className="mt-2">Daily Feedback</Link>
			</div>
		</div>
	);
}
