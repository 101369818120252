import React from "react";
import { Modal, ModalBody } from "reactstrap";

const ErrorPopup = ({ toggle, message, subMessage, img }) => {
  return (
    <Modal isOpen={true}>
      <ModalBody>
        <span
          onClick={() => toggle()}
          style={{
            color: "#93c13e",
            opacity: "unset",
            position: "absolute",
            top: "4%",
            right: "3%",
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </span>
        <div className="text-center">
          {img && img}
          <br />
        </div>
        {message && (
          <div className="mt-4 text-center">
            <h6>{message}</h6>
            {subMessage && (
              <div className="">
                <small>{subMessage}</small>
              </div>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ErrorPopup;
