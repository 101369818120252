import React, { useState } from "react";

export default function SidebarClose(props) {


	const closeSidebar = () => {
		document.body.classList.add('leftSidebarClosed');
	}

	const OpenSidebar = () => {
		document.body.classList.remove('leftSidebarClosed');
	}

	return (
		<div className="sidebar-wrapper">
			<span className="toggleIcon closeSidebar align-self-end mb-3 pr-3" onClick={() => closeSidebar()}>
				<img src="/images/new_dash/toggleIcon.svg" alt="collapse icon" />
			</span>
			<span className="openSidebar align-self-end mb-3 pr-3" onClick={() => OpenSidebar()}>
				<img src="/images/new_dash/toggleIcon.svg" />
			</span>
		</div>
	);
}
