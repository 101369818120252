import React from "react";
import { Link } from "react-router-dom";
import UserSubscriptions from "components/Dashboard/UserSubscriptions.jsx";
import UpcomingClasses from "components/Dashboard/UpcomingClasses.jsx";

export default function RightSidebar(props) {
  const { classes, subscription } = props;

  return (
    <div className="rightSidebar">
      <div className="rightWrap">
        <div className="row">
          <div className="col d-flex justify-content-center mb-5 mt-4">
            <Link to="/" style={{ textDecoration: "none" }}>
              <span className="sheen-gold pte-core__title">PTE CORE</span>
            </Link>
          </div>
          <div className="text-center mb-3">
            <a
              href={`${process.env.REACT_APP_LA_URL}/admin/dashboard`}
              className="pte-academic-button"
            >
              Go to PTE Academic
            </a>
          </div>
        </div>
        <UserSubscriptions subscription={subscription} />
        {subscription.length > 0 && <UpcomingClasses classes={classes} />}
      </div>
    </div>
  );
}
