import React, { Component } from "react";
import { createBrowserHistory } from "history";
import {
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Sub from "layouts/Sub.jsx";
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { connect } from "react-redux";
import { setAlert, intStatus } from "action/index.js";
import NotificationAlert from "react-notification-alert";
import { store, persistor } from "store/index";
import Home from "./page/Home";
import SignupPortal from "./page/pteportal/auth/Register";
import ForgotPortal from "./page/pteportal/auth/Forgot";
import Term from "./page/Term";
import Feedback from "./page/Feedback";
import DailyFeedback from "./page/DailyFeedback";
import Refund from "./page/Refund";
import TestimonialAll from "./page/TestimonialAll";
import ScoreCardAll from "./page/ScoreCardAll";
import NotFound from "./page/NotFound";
import ThankYou from "./page/ThankYou";
import ThankYouRegister from "./page/ThankYouRegister";
import robot from "./components/robots.txt";
import Sitemap1 from "./components/sitemap.xml";
import fb from "./components/googleaa7a8b2d6a176fd2.txt";
import Maintenance from "./page/Maintenance";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from "notistack";
import ChromePopUp from "components/PopUps/Chrome";
import LaLoader from "components/Loader/LaLoader.jsx";
import { setLoader } from "action";
import PrivacyPolicy from "page/PrivacyPolicy";
import { ToastContainer } from "react-toastify";
import Default from "layouts/Default";

const hist = createBrowserHistory();

const mapStateToProps = (state) => {
  return {
    token: state.token,
    loaded: state.loaded,
    user: state.user,
    subscribed: state.subscribed,
    alert: state.alert,
  };
};

class ReactRouteAll extends Component {
  // constructor(props) {
  // 	super(props);
  // 	this.state = {
  // 	  isMounted: false,
  // 	  os: ""
  // 	};
  //   }

  isPortalUrl = (pathname) => {
    const starts_with = pathname.indexOf("/admin/");
    if (starts_with === 0) {
      document.querySelector("html").classList.add("student-portal");
    } else {
      document.querySelector("html").classList.remove("student-portal");
    }
  };

  isDashboard = (pathname) => {
    const starts_with = pathname.indexOf("/admin/dashboard");
    if (starts_with === 0) {
      document.querySelector("html").classList.add("student-dashboard");
    } else {
      document.querySelector("html").classList.remove("student-dashboard");
    }
  };

  componentDidMount() {
    store.dispatch(setLoader(true));
    store.dispatch(intStatus(true));
    if (this.props.loaded) {
      window.addEventListener("load", () => {
        let snackbarId;
        // 1st, we set the correct status when the page loads
        // navigator.onLine
        // 	? closeSnackbar(snackbarId)
        // 	: snackbarId =  enqueueSnackbar("No internet connection", {
        // 		variant: "error",
        // 		persist: true,
        // 	});

        // now we listen for network status changes
        window.addEventListener("online", () => {
          closeSnackbar(snackbarId);
          store.dispatch(intStatus(true));
          document.getElementById("internet-down").remove();
        });

        window.addEventListener("offline", () => {
          snackbarId = enqueueSnackbar("No internet connection", {
            variant: "error",
            persist: true,
          });
          store.dispatch(intStatus(false));
          // if (window.location.pathname.includes('/admin/test') || window.location.pathname.includes('/admin/practice')) {
          const div = document.createElement("div");
          div.className = "modal-backdrop fade show";
          div.id = "internet-down";
          if (document.getElementsByClassName("wrapper").length > 0) {
            document.getElementsByClassName("wrapper")[0].appendChild(div);
          } else if (
            document.getElementsByClassName("dashboardPage").length > 0
          ) {
            document
              .getElementsByClassName("dashboardPage")[0]
              .appendChild(div);
          } else {
            document.getElementById("root").appendChild(div);
          }
          // }
        });
      });
    }

    // check pathname on page load.
    setTimeout(() => {
      this.isPortalUrl(hist.location.pathname);
      this.isDashboard(hist.location.pathname);
    }, 500);

    // listen to navigation (path change.)
    this.unlisten = hist.listen((location) => {
      this.isPortalUrl(location.pathname);
      this.isDashboard(location.pathname);
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  notify = (type, message) => {
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    const portal = [];
    const portalAuth = [
    //   { path: "/auth/login", component: LoginPortal },
      { path: "/auth/register", component: SignupPortal },
      { path: "/auth/forgot", component: ForgotPortal },
    ];
    const subAuth = [
		// { path: "/auth/login", component: LoginPortal }
	];
    const front = [
      { path: "/", component: Home },
      { path: "/robots.txt", component: robot },
      { path: "/googleaa7a8b2d6a176fd2.html", componen: fb },
      { path: "/maintenance", component: Maintenance },
      { path: "/mock-feedback/:id", component: Feedback },
      { path: "/daily-feedback", component: DailyFeedback },
      { path: "/term", component: Term },
      { path: "/privacy-policy", component: PrivacyPolicy },
      { path: "/testimonial-all", component: TestimonialAll },
      { path: "/refund-policies", component: Refund },
      { path: "/scorecard", component: ScoreCardAll },
      { path: "/sitemap.xml", component: Sitemap1 },
      { path: "/thank-you", component: ThankYou },
      { path: "/register-success", component: ThankYouRegister },
    ];
    const isMainSite =
      window.location.hostname.split(".").shift() === "www" ? true : false;
    if (this.props.alert.flag) {
      this.notify(this.props.alert.type, this.props.alert.message);
      store.dispatch(setAlert({ flag: false }));
    }
    return (
      <React.Fragment>
        <LaLoader show={!this.props.loaded} />
        <SnackbarProvider maxSnack={2} />
        <ChromePopUp />
        <NotificationAlert ref="notificationAlert" />
        {!store.getState().is_portal && store.getState().is_main && (
          <Router history={hist}>
            <Switch>
              {front.length > 0 &&
                front.map((props) => (
                  <Route key={props.path} {...props} exact />
                ))}
              {
                <Route
                  path="/auth"
                  render={(props) => <AuthLayout {...props} />}
                />
              }
              {
                <Route
                  path="/default"
                  render={(props) => <Default {...props} />}
                />
              }
              {this.props.token != null && (
                <Route
                  path="/admin"
                  render={(props) => <AdminLayout {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/sub/subscription"
                  render={(props) => <Sub {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/admin/sub/payment/form"
                  render={(props) => <AdminLayout {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/admin/sub/success"
                  render={(props) => <AdminLayout {...props} />}
                />
              )}{" "}
              />}
              {false && this.props.token != null && (
                <Redirect to="/admin/dashboard" />
              )}
              {this.props.token === null && (
                <Redirect to="/default/loginpage" />
              )}
              {this.props.token != null && <Redirect to="/admin/dashboard" />}
              {false && this.props.token != null && !this.props.subscribed && (
                <Redirect to="/sub/subscription" />
              )}
              <Route component={NotFound} exact />
            </Switch>
          </Router>
        )}
        {store.getState().is_portal && store.getState().is_main && (
          <Router history={hist}>
            <Switch>
              {portal.length > 0 &&
                portal.map((props) => <Route {...props} exact />)}
              {portalAuth.length > 0 &&
                portalAuth.map((props) => <Route {...props} exact />)}
              {this.props.token != null && (
                <Route
                  path="/admin"
                  render={(props) => <AdminLayout {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/sub/subscription"
                  render={(props) => <Sub {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/sub/payment/form"
                  render={(props) => <Sub {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/sub/success"
                  render={(props) => <Sub {...props} />}
                />
              )}
              {false && this.props.token != null && (
                <Redirect to="/admin/dashboard" />
              )}
              {this.props.token === null && <Redirect to="/auth/login" />}
              {this.props.token != null && <Redirect to="/admin/dashboard" />}
              {false && this.props.token != null && !this.props.subscribed && (
                <Redirect to="/sub/subscription" />
              )}
              <Route component={NotFound} exact />
            </Switch>
          </Router>
        )}
        {!store.getState().is_main && (
          <Router history={hist}>
            <Switch>
              {subAuth.length > 0 &&
                subAuth.map((props) => <Route {...props} exact />)}
              {this.props.token != null && (
                <Route
                  path="/admin"
                  render={(props) => <AdminLayout {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/sub/subscription"
                  render={(props) => <Sub {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/sub/payment/form"
                  render={(props) => <Sub {...props} />}
                />
              )}
              {this.props.token != null && (
                <Route
                  path="/sub/success"
                  render={(props) => <Sub {...props} />}
                />
              )}
              {false && this.props.token != null && (
                <Redirect to="/admin/dashboard" />
              )}
              {this.props.token === null && <Redirect to="/auth/login" />}
              {this.props.token != null && <Redirect to="/admin/dashboard" />}
              {false && this.props.token != null && !this.props.subscribed && (
                <Redirect to="/sub/subscription" />
              )}
              <Route component={NotFound} exact />
            </Switch>
          </Router>
        )}
        <ToastContainer
          position="top-right"
          autoClose={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="light"
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(ReactRouteAll);
