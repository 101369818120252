import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import ChromeCheck from "views/ChromeCheck.jsx";
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	Alert
} from "reactstrap";
import { Link } from "react-router-dom";

export default function SpeakingTestingApis(props) {
	const [all_ok, setAllOk] = useState(true);

	return (
		<>
			<Helmet>
				<title>Top PTE Training Institute</title>
			</Helmet>

			{!all_ok && <ChromeCheck />}

			{all_ok &&
				<div className="content multipleCards">
					<Row>
						<Col xs={12}>
							<Card>
								<CardHeader>
									<CardTitle tag="h4">
										Speaking Zone
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Row>
										<Col xs={3}>
											<Link to={'/admin/testinggs/questionList/0/1'}>
												<Alert color="primary">Read Aloud</Alert>
											</Link>
										</Col>
										{/*<Col xs={3}>
											<Link to={'/admin/testinggs/questionList/0/2'}>
												<Alert color="primary">Repeat Sentence</Alert>
											</Link>
										</Col>*/}
										
										<Col xs={3}>
											<Link to={'/admin/testinggs/questionList/0/3'}>
												<Alert color="primary">Describe Image</Alert>
											</Link>
										</Col>
										{/*<Col xs={3}>
											<Link to={'/admin/testinggs/questionList/0/4'}>
												<Alert color="primary">Respond to Situation</Alert>
											</Link>
										</Col>
										<Col xs={3}>
											<Link to={'/admin/testinggs/questionList/0/5'}>
												<Alert color="primary">Answer Short Questions</Alert>
											</Link>
										</Col>*/}
										<Col xs={3}>
											<Link to={'/admin/testing/stt'}>
												<Alert color="primary">New Speech to Text Package</Alert>
											</Link>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			}
		</>
	);
}