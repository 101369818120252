import axios from 'axios';
import cookie from 'react-cookies';
import {store,persistor} from 'store/index';
import { setToken,setLoader, setSub  } from 'action/index.js';
store.subscribe(listener);

let base = process.env.REACT_APP_API_URL;

// const base = `https://ladevtesting.languageacademy.com.au`;
// const s3base = `https://testing.languageacademy.com.au`;
// const s3base = `http://127.0.0.1:8000`;
const s3base = 'https://s3.ap-southeast-2.amazonaws.com/lamedia21';
const axiosInstance = axios.create({
	baseURL: `${base}/api/v2`
});

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axiosInstance.defaults.headers.common['Cache-Control'] = 'no-store';
axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

function select(state) {
  return state.token
}
function listener() {
  let token = select(store.getState())
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


const errorHandler = (error) => {
  if (error.config.url !== 'https://la-ping.languageacademy.com.au/connected') {
    if(
      error.response.status === 503 && 
      error.response.data.status === 'server is down for maintenance'
    ) {
      window.location.href = '/maintenance';
    }
  

  	if (isHandlerEnabled(error.config)) {
  	// Handle errors
  	}
  	store.dispatch(setLoader(true));
  	console.error(error);

  	// if (error !== undefined && error.response !== undefined) {
  		if(error.response.status === 401){
  			store.dispatch(setToken(null));
  		}
  		if(error.response.status === 402){
  			store.dispatch(setSub(false));
  		}
  	// }
  	store.dispatch(setLoader(true));
  }
	return error;
}

const isHandlerEnabled = (config={}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ?
    false : true
}

const requestHandler = (request) => {
  if (request.url !== "check/payment" && request.url !== "delete/question/responseNew" && !(/^submit\/practice\?random=\d{1,9}$/.test(request.url)) && request.url !== "show/history" && request.url !== "show/comment" && request.url !== "show/notes" && request.url !== "submit/mock" && request.url !== 'set/mockTime' && request.url !== 'set/tag' && request.url !== 'dashboard/data2' && request.url !== 'https://la-ping.languageacademy.com.au/connected' && request.url !== 'submitFailed/mock' ) {
    store.dispatch(setLoader(false));
  }

  return request
}

axiosInstance.interceptors.request.use(
  request => requestHandler(request)
)

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  store.dispatch(setLoader(true));
  // console.log(store.getState().token);
  return response
}

export default axiosInstance;
export {base,s3base};
